import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// components
import GenericDrawer from './GenericDrawer';

// utilities
import api from '../../api';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';

// style
import '../../styles/addEditAdvisorGroupDrawer.css';

interface AdvisorGroup {
    _id: string;
    name: string;
}

interface User {
    _id: string;
    name: string;
    access: string;
}

interface GroupPlan {
    _id: string;
    plan_name: string;
}

interface CommonData {
    name?: string;
    owner?: User;
    plan?: GroupPlan;
}

interface Props extends RouteComponentProps {
    advisorGroup?: AdvisorGroup;
    user: User;
    close: () => void;
    loading: (duration?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: any, isError?: boolean) => void;
    reloadData?: () => void;
}

const AddEditAdvisorGroupDrawer: React.FC<Props> = ({ advisorGroup, user, close, loading, loaded, setAlert, reloadData }) => {
    const [commonData, setCommonData] = useState<CommonData>({});
    const [groupPlans, setGroupPlans] = useState<GroupPlan[]>([]);
    const [allAdvisors, setAllAdvisors] = useState<User[]>([]);
    const [errors, setErrors] = useState<Record<string, string>>({});

    useEffect(() => {
        loadInitialData();
    }, [advisorGroup]);

    const loadInitialData = async () => {
        loading?.(0, 'addEditAdvisorGroupDrawer');

        try {
            if (groupPlans.length === 0) {
                const plans = await api.get('/advisorygroupplans');
                setGroupPlans(plans);
            }

            if (allAdvisors.length === 0) {
                try {
                    const advisors = await api2.paginateApiRoute(async (paginate_params: any) => {
                        return (
                            await api2.client.UserApi.listUsers({
                                ...paginate_params,
                                access: 'advisor',
                            })
                        ).data.users;
                    });
                    setAllAdvisors(advisors);
                } catch (error) {
                    console.error('Error Fetching Users', error);
                    setAllAdvisors([]);
                }
            }

            if (advisorGroup) {
                setCommonData({
                    name: advisorGroup.name,
                });
            }
        } catch (error) {
            console.error('Error loading data:', error);
        }

        loaded?.('addEditAdvisorGroupDrawer');
    };

    const getFields = useCallback(
        () => ({
            'Firm Name': {
                placeholder: 'Enter Firm Name',
                label: 'Firm Name',
                fieldName: 'name',
                type: 'text',
                optionField: 'name',
                optionClearable: false,
                value: commonData.name,
            },
            ...(user.access === 'admin'
                ? {
                      Owner: {
                          placeholder: `Select Owner${user.access === 'admin' ? '' : ' (optional)'}`,
                          label: 'Owner',
                          fieldName: 'owner',
                          type: 'select',
                          options: allAdvisors,
                          optionField: 'name',
                          optionClearable: true,
                          value: commonData.owner,
                      },
                      Plan: {
                          placeholder: 'Select Plan',
                          label: 'Plan',
                          fieldName: 'plan',
                          type: 'select',
                          options: groupPlans,
                          optionField: 'plan_name',
                          optionClearable: true,
                          value: commonData.plan,
                      },
                  }
                : {}),
        }),
        [user.access, allAdvisors, groupPlans, commonData]
    );

    const isSafeToSave = (setErrorState = true, formData?: CommonData) => {
        const newErrors: Record<string, string> = {};

        const dataToCheck = formData || commonData;

        if (!dataToCheck?.name) {
            newErrors['name'] = 'This field is required.';
        }

        if (!dataToCheck?.owner && user.access === 'admin') {
            newErrors['owner'] = 'This field is required.';
        }

        if (!dataToCheck?.plan && user.access === 'admin') {
            newErrors['plan'] = 'This field is required.';
        }

        const isSafe = Object.keys(newErrors).length === 0;

        if (setErrorState) {
            newErrors.submit = isSafe ? '' : 'There are missing required fields.';
            setErrors(isSafe ? {} : newErrors);
        }
        return isSafe;
    };

    const onSubmit = async (formData: CommonData) => {
        if (!isSafeToSave(true, formData)) {
            return;
        }

        loading(320, 'createOrUpdateAdvisorGroup');

        try {
            if (!advisorGroup) {
                const newUserBody: Record<string, any> = {
                    name: formData.name,
                    plan: formData.plan?._id,
                    owner_id: formData.owner?._id,
                };

                const newUserRes = await api.post('/advisorygroups', newUserBody, {
                    400: (err: Error) => setErrors({ name: err.message }),
                    500: (err: Error) => setErrors({ submit: err.message }),
                });

                if (newUserRes?.message === 'OK') {
                    setDismissableAlert(setAlert, 'New group created.');
                    reloadData?.();
                    close();
                }
            } else {
                const groupUpdateRes = await api.patch(`/advisorygroups/${advisorGroup._id}`, {
                    name: formData.name,
                    owner: formData.owner,
                    plan: formData.plan?._id,
                });

                if (groupUpdateRes) {
                    setDismissableAlert(setAlert, 'Group data updated.');
                    reloadData?.();
                } else {
                    setDismissableAlert(setAlert, 'Error updating Group data.', true);
                }
            }
        } finally {
            loaded('createOrUpdateAdvisorGroup');
        }
    };

    const onDelete = async () => {
        try {
            await api.delete(`/advisorygroups/${advisorGroup?._id}`);
            setDismissableAlert(setAlert, 'Group deactivated.');
            reloadData?.();
            close();
        } catch (error) {
            console.error('Error deactivating group:', error);
            setDismissableAlert(setAlert, 'Error deactivating group', true);
        }
    };

    return (
        <GenericDrawer
            title={advisorGroup ? 'Edit Group' : 'Add Group'}
            actionStr={advisorGroup ? 'Save Changes' : 'Add'}
            loadingKey="addEditAdvisorGroupDrawer"
            getFields={getFields}
            onSubmit={onSubmit}
            onDelete={advisorGroup ? onDelete : undefined}
            close={close}
            loading={loading}
            loaded={loaded}
            loadData={loadInitialData}
            errors={errors}
            setErrors={setErrors}
            className="addNewAdvisorGroup-drawer"
            isSafeToSave={isSafeToSave}
        />
    );
};

export default withRouter(AddEditAdvisorGroupDrawer);
