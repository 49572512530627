// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import CapitalCallsWidget from './fakeData/capitalCallsWidgetFakeData';
import AccountPerformanceWidget from './fakeData/accountPerformanceWidgetFakeData';
import MonthlyChartWidget from './fakeData/monthlyChartWidgetFakeData';
import PerformancePage from './fakeData/performancePageFakeData';
import DocumentsPage from './fakeData/documentsPageFakeData';
import InvestmentsPage from './fakeData/investmentsPageFakeData';
import DocumentsWidget from './fakeData/documentsWidgetFakeData';
import TotalValue from './fakeData/totalValueFakeData';

interface EmptyStateFakeData {
    CapitalCallsWidget: typeof CapitalCallsWidget;
    AccountPerformanceWidget: typeof AccountPerformanceWidget;
    MonthlyChartWidget: typeof MonthlyChartWidget;
    TotalValue: typeof TotalValue;
    PerformancePage: typeof PerformancePage;
    DocumentsPage: typeof DocumentsPage;
    InvestmentsPage: typeof InvestmentsPage;
    DocumentsWidget: typeof DocumentsWidget;
}

const emptyStateFakeData: EmptyStateFakeData = {
    CapitalCallsWidget,
    AccountPerformanceWidget,
    MonthlyChartWidget,
    TotalValue,
    PerformancePage,
    DocumentsPage,
    InvestmentsPage,
    DocumentsWidget,
};

export default emptyStateFakeData;
