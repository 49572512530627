import dateToUTCMidnight from '../date/dateToUTCMidnight';

/**
 * Gets the total value of an investment or array of investments.
 *
 * @param {investment | investment[]} investmentSingleOrArray
 * @returns {number} total value (realized and unrealized) of investments
 */
export function calcTotalValue(investmentSingleOrArray) {
    if (Array.isArray(investmentSingleOrArray)) {
        return investmentSingleOrArray.reduce((val, inv) => {
            const invVal = calcTotalValue(inv);
            if (Number.isFinite(invVal)) {
                val += invVal;
            }
            return val;
        }, 0);
    } else {
        const valuation = investmentSingleOrArray?.valuation || 0;
        const distributions = investmentSingleOrArray?.performance?.itd?.distributions || 0;
        const ret = valuation + distributions;
        return ret;
    }
}
/**
 *  Gets the total unfunded commitment of investment(s).
 *
 * @param {investment | investments[]} investmentSingleOrArray
 * @returns {number} total unfunded commitment of investment(s)
 */
export function calcUnfundedCommitments(investmentSingleOrArray) {
    if (Array.isArray(investmentSingleOrArray)) {
        return investmentSingleOrArray.reduce((val, inv) => {
            const invUnfundedCommitments = inv?.performance?.itd?.unfunded_to_date;
            if (Number.isFinite(invUnfundedCommitments)) {
                val += invUnfundedCommitments;
            }
            return val;
        }, 0);
    } else {
        return investmentSingleOrArray?.performance?.itd?.unfunded_to_date;
    }
}

/**
 * Calculates MoIC for single investment.
 *
 *  NOTE that this is the gain/loss since inception and does not account for
 *  beginning valuation. So if early data is missing, this number will be
 *  incorrect.
 *
 * @param {investment} investment
 * @returns {number} MoIC
 */
export function calcMoic(investment) {
    if (!investment?.performance?.itd?.contributions) return; // undefined
    return (investment.valuation + investment.performance?.itd?.distributions) / investment.performance?.itd?.contributions;
}

/**
 * For a hedge fund investment, calculates additions/withdrawals NOTE:
 *  does not currently validate that investment is hedge fund.
 *  Additions/Withdrawals as of writing this is only calculated for
 *  hedge funds; this formula may not be suitable for all investments.
 * @param {investment} investment
 * @returns {number} additions/withdrawals
 */
export function calcAdditionsWithdrawals(investment) {
    let ret = investment.performance?.itd?.contributions - investment.performance?.itd?.distributions - (investment?.initial_contribution ?? 0);
    return ret;
}

/**
 * Calculates the ROI of an investment for the given year. If no year is given,
 * returns the roi of the investment since inception.
 *
 * @param {investment} investment
 * @param {number?} year
 * @returns
 */
export function calcROI(investment, year = undefined) {
    let ret = undefined;
    if (year === undefined) {
        ret = investment.performance?.itd?.roi ?? 0;
    } else {
        let monthlyData = investment?.performance?.monthly ?? [];
        let yearData = monthlyData.filter((m) => m.year === year);
        let yrStartVal = yearStartValue(investment, year);
        // console.log("YEAR: ", year);

        const { totalGain, totalContributions } = yearData.reduce(
            (sum, md) => {
                if (Number.isFinite(md?.snapshot?.contributions)) {
                    sum.totalContributions += md?.snapshot?.contributions;
                }
                if (Number.isFinite(md?.snapshot?.gain)) {
                    sum.totalGain += md?.snapshot?.gain;
                }
                return sum;
            },
            { totalGain: 0, totalContributions: 0 }
        );
        // console.log("GAIN: ", totalGain, " CONTS: ", totalContributions);
        ret = yrStartVal + totalContributions > 0 ? totalGain / (yrStartVal + totalContributions) : 0;
    }
    return ret;
}

function yearStartValue(investment, year) {
    let ret = 0;
    let monthlyData = investment?.performance?.monthly ?? [];
    // console.log("YEAR: ", year)
    if (monthlyData) {
        let priorYearPerformance = monthlyData.filter((md) => md.year < year && Number.isFinite(md?.valuation));

        //Sort descending
        priorYearPerformance.sort((md1, md2) => dateToUTCMidnight(new Date(md2.year, md2.month)) - dateToUTCMidnight(new Date(md1.year, md1.month)));
        ret = priorYearPerformance?.[0]?.valuation ?? 0;
    }
    return ret;
}

/**
 * Gets amount invested for investment(s)
 *
 * @param {investment[] | investment} investments
 * @returns {number} amount invested
 */
export function calcAmountInvested(investments) {
    // let ret = 0;
    if (Array.isArray(investments)) {
        return investments.reduce((val, inv) => {
            let invested = inv?.performance?.itd?.contributions;
            if (Number.isFinite(invested)) {
                val += invested;
            }
            return val;
        }, 0);
    } else {
        return investments?.performance?.itd?.contributions;
    }
}
