import { FileParseEditResult, FileParseResult, FileTextResult, FileTypes, SingleFileParseEditResult } from '../../../../constants/file';

export enum BulkUploadType {
    commitment = 'commitment',
    valuation = 'valuation',
    transaction = 'transaction',
    account = 'account',
    investment = 'investment',
    user = 'user',
    investor = 'investor',
    advisor = 'advisor',
    investment_master = 'investment_master',
    contact = 'contact',
    advisory_group = 'advisory_group',
    connection = 'connection',
    ral_request = 'ral_request',
}

export const BulkUploadTypeExcludeList: BulkUploadType[] = [BulkUploadType.user];

export const BulkUploadTypeNameMap: Record<BulkUploadType, string> = {
    commitment: 'Commitments',
    valuation: 'Valuations',
    transaction: 'Transactions',
    account: 'Accounts',
    investment: 'Investments',
    user: 'Users',
    investor: 'Investors',
    advisor: 'Advisors',
    investment_master: 'Investment Masters',
    contact: 'Contacts',
    advisory_group: 'Advisory Groups',
    connection: 'Connections',
    ral_request: 'RAL Requests',
};

export enum BulkUploadDataType {
    number = 'number',
    date = 'date',
    text = 'string',
    id = 'id',
    option = 'option',
    boolean = 'boolean',
}

export interface BulkUploadColumnBase<T = any> {
    dataType: BulkUploadDataType;
    required?: boolean;
    format: (val: string) => T;
    defaultValue?: T;
    isValid: (val: T, data?: T) => boolean;
    invalidMessage?: string;
    style: React.CSSProperties;
    getEnumOptions?: (data: T) => string[];
    options?: string[];
}

export interface BulkUploadColumn<T = any> extends BulkUploadColumnBase<T> {
    displayName: string;
    fieldName: string;
}

export interface BulkUploadRequestResult {
    success: boolean;
    message: string;
    id?: string;
}

export interface BulkDataTypeObjectType<T = any> {
    type: string;
    allowedFileTypes: FileTypes[];
    getColumns: () => { [key: string]: BulkUploadColumn };
    getNotes: () => string[];
    parseTextFileResult: (results: FileTextResult) => Promise<FileParseResult<T>>;
    create: (columnObj: { [key: string]: BulkUploadColumn }, data: { [key: string]: any }) => Promise<BulkUploadRequestResult>;
    delete: (id: string) => Promise<BulkUploadRequestResult>;
}

export const enum BulkUploadStatus {
    Pending = 'Pending',
    Deleted = 'Deleted',
    Submitted = 'Submitted',
    Error = 'Error',
}

export interface SingleFileParseBulkUploadEditResult<T = Record<string, string>> extends SingleFileParseEditResult<T, BulkUploadStatus> {}

export interface FileParseBulkUploadEditResult<T> extends FileParseEditResult<T, SingleFileParseBulkUploadEditResult<T>> {}

export interface BulkUploadHeaderComponent {
    renderChildren: () => JSX.Element;
    style?: React.CSSProperties;
}

export interface BulkUploadFileResult<T> {
    rawTextFileResult: FileTextResult;
    textFileResult: FileTextResult | null;
    fileParsingResult: FileParseResult<T> | null;
}
