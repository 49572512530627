import React from 'react';
import '../../styles/widget.css';
import '../../styles/widget-MonthlyReturns.css';
import { calcROI } from '../../utilities/calculate/financeCalculations';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';
import { EM_DASH } from '../../constants/constantStrings';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';

const COLUMNS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

interface MonthlyData {
    year: number;
    month: number;
    snapshot: {
        roi: number;
    };
}

interface Investment {
    performance?: {
        monthly?: MonthlyData[];
    };
}

interface MonthlyReturnsWidgetProps {
    investment?: Investment;
    data?: MonthlyData[];
}

const MonthlyReturnsWidget: React.FC<MonthlyReturnsWidgetProps> = ({ investment, data = [] }) => {
    const monthlyReturnsCell = (month: string, year: number, monthNum: number) => {
        const monthlyData = investment?.performance?.monthly ?? [];
        let data = monthlyData.find((md) => md.year === year && md.month === monthNum);
        let cssClass = 'widget-table-data-null';
        const roiData = data?.snapshot?.roi;

        let widgetText = Number.isFinite(roiData ?? undefined)
            ? (100 * roiData!).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
              }) + '%'
            : '—';

        if (roiData !== undefined && roiData > 0) {
            cssClass = 'widget-table-data-positive';
        } else if (roiData !== undefined && roiData < 0) {
            cssClass = 'widget-table-data-negative';
        }

        return (
            <td className={cssClass} key={monthNum.toString()}>
                {widgetText}
            </td>
        );
    };

    const getTotalCell = (year: number) => {
        const yearROI = calcROI(investment, year);
        let cssClass = 'widget-table-data-null';
        let widgetText = yearROI
            ? (100 * yearROI).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
              }) + '%'
            : EM_DASH;

        if (yearROI > 0) {
            cssClass = 'widget-table-data-positive';
        } else if (yearROI < 0) {
            cssClass = 'widget-table-data-negative';
        }

        return (
            <td className={cssClass} key={year.toString()}>
                {widgetText}
            </td>
        );
    };

    let date = dateToUTCMidnight();
    let latestYear = date instanceof Date ? date.getUTCFullYear() : date.toDate().getUTCFullYear();

    if (data.length) {
        const allYears = new Set(data.map((qd) => qd.year));
        const yearlyMax = Math.max(...Array.from(allYears));
        latestYear = isFiniteNonzero(yearlyMax) ? yearlyMax : latestYear;
    }

    const displayYears = Array.from({ length: 4 }, (_, i) => latestYear - i);

    return (
        <div className="widget">
            <div className="widget-header">Monthly Returns</div>
            <table className="widget-table monthly-returns-table">
                <thead>
                    <tr key="tableheader">
                        <th key="h_null" />
                        {COLUMNS.map((month) => (
                            <th key={'mthyear' + month}>{month}</th>
                        ))}
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {displayYears.map((year) => (
                        <tr key={'row_' + year}>
                            <th key="year_col">{year}</th>
                            {COLUMNS.map((month, idx) => monthlyReturnsCell(month, year, idx))}
                            {getTotalCell(year)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MonthlyReturnsWidget;
