import React, { useEffect, useState } from 'react';
import { Currency } from '../../types/Currency';
import { Investment } from '../../types/Investment';
import formatCurrency from '../../utilities/format/formatCurrency';
import { formatUTCDate } from '../../utilities/format/formatDate';

interface MostRecentValuationProps {
    investment: Investment;
    currency: Currency;
    shouldDisplayDate?: boolean;
    onValuationFetched?: (valuation: { amount: number; date: string }) => void;
}

const MostRecentValuation: React.FC<MostRecentValuationProps> = ({ investment, currency, shouldDisplayDate = true, onValuationFetched }) => {
    const [valuation, setValuation] = useState<{ amount: number; date: string } | null>(null);

    useEffect(() => {
        if (investment.most_recent_valuation?.amount !== undefined && investment.most_recent_valuation?.date !== undefined) {
            setValuation({
                amount: investment.most_recent_valuation.amount,
                date: investment.most_recent_valuation.date,
            });
            if (onValuationFetched) {
                onValuationFetched({
                    amount: investment.most_recent_valuation.amount,
                    date: investment.most_recent_valuation.date,
                });
            }
        }
    }, [investment, onValuationFetched]);

    if (!valuation) {
        return <div>No Valuation Data Available</div>;
    }

    return (
        <div>
            {formatCurrency(valuation.amount, currency)}
            {shouldDisplayDate ? ` (as of ${formatUTCDate(valuation.date)})` : ''}
        </div>
    );
};

export default MostRecentValuation;
