import React, { Component } from 'react';
import api from '../../../api';
import { VUES } from '../../../constants/constantStrings';
import '../../../styles/supertool.css';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';
import rephraseClientStatus from '../../../utilities/AdvisorVue/rephraseClientStatus';
import { setDismissableAlert } from '../../../utilities/alert/setDismissableAlert';
import DetailedAlert from '../../Alerts/DetailedAlert';
import ReSLAButton from '../../Buttons/ReSLAButton';
import AddEditAccountDrawer from '../../Drawers/AddEditAccountDrawer';
import AddEditConnectionDrawer from '../../Drawers/AddEditConnectionDrawer';
import AddInvestmentDrawer from '../../Drawers/AddInvestmentDrawer';
import Multitoggle from '../../Toggles/Multitoggle';

interface User {
    _id: string;
    name: string;
    status?: string;
    is_deactivated?: boolean;
    access?: string;
}

interface Account {
    _id: string;
    name: string;
    type: string;
    investments?: Investment[];
}

interface Investment {
    _id: string;
    name: string;
    type: string;
    account_id: string;
}

interface SupertoolBarProps {
    relevantUser?: User;
    closeDrawer: () => void;
    setAlert: (message: React.ReactNode, isError?: boolean) => void;
    loading: (duration?: number, key?: string) => Promise<void>;
    loaded: (key?: string) => Promise<void>;
    loadUserData?: () => Promise<void>;
    loadUser: (id: string) => Promise<void>;
    onUpdate?: () => Promise<void>;
    match?: {
        params: {
            id?: string;
        };
    };
    openDrawer: (component: () => React.ReactNode) => void;
    accounts?: Account[];
    advisors?: User[];
    reloadData: () => Promise<void>;
    vue?: string;
    user?: User;
    history: {
        push: (path: string) => void;
    };
    connections?: any[];
    firm?: any;
    advisor?: any;
}

interface State {
    connection: {
        name: string;
        integration: null;
    };
    openAddInvestmentsDropdown: boolean;
    tooltips: {
        stagingActiveToggle?: boolean;
        [key: string]: boolean | undefined;
    };
    openAdvisorList: boolean;
    openCreateSection: boolean;
    mustConfirmActivation?: boolean;
    toggleState?: string;
    isDeactivated?: boolean;
    selectedAdvisor?: string;
}

interface ApiResponse {
    token?: string;
    message?: string;
    success?: boolean;
}

export default class SupertoolBar extends Component<SupertoolBarProps, State> {
    state: State = {
        connection: {
            name: '',
            integration: null,
        },
        openAddInvestmentsDropdown: false,
        tooltips: {},
        openAdvisorList: false,
        openCreateSection: false,
    };

    componentDidMount = () => {
        this.resetState();
    };

    componentDidUpdate = (prevProps: SupertoolBarProps) => {
        if (prevProps.relevantUser?._id !== this.props.relevantUser?._id) {
            this.resetState();
        } else if (prevProps.relevantUser?.status !== this.props.relevantUser?.status) {
            this.resetState();
        }
    };

    componentWillUnmount = () => {
        this.props.closeDrawer();
    };
    resetState = () => {
        this.props.setAlert(undefined, undefined);
        this.props.closeDrawer();
        this.setState({
            mustConfirmActivation: this.props.relevantUser?.status === 'Staging',
            toggleState: this.props.relevantUser?.status === 'Staging' ? 'Staging' : 'Active',
            isDeactivated: this.props.relevantUser?.is_deactivated || false,
        });
    };

    confirmActivation = async () => {
        this.props.loading();
        this.props.setAlert(null, undefined);
        await this.setStatus('Active');
        if (this.props.match?.params?.id) {
            if (this.props.loadUserData) {
                await this.props.loadUserData();
            } else if (this.props.loadUser) {
                await this.props.loadUser(this.props.match.params.id.split('?')[0]);
            }
        }
        if (this.props.onUpdate) {
            await this.props.onUpdate();
        }

        this.props.loaded();
    };

    setStatus = async (status: string) => {
        this.props.loading(320, 'setStatus');
        const res: ApiResponse = await api.patch(
            `/users/${this.props.relevantUser?._id}`,
            { status },
            {
                400: (res: { message: string }) => {
                    setDismissableAlert(this.props.setAlert, res.message, true, 10000);
                },
            }
        );
        this.props.loaded('setStatus');
        if (res) {
            this.setState({ toggleState: status });
        }
    };

    toggleState = async (selection: string) => {
        if (!UserPermissions().canSetUserStagingActive) return;

        if (selection === this.state.toggleState) {
            return;
        } else if (selection === 'Active') {
            this.props.setAlert?.(
                <DetailedAlert
                    title="Are you sure you want to activate this Client?"
                    description="Client will be notified once activation is confirmed."
                    buttonText="Confirm Activation"
                    buttonId="confirm-activation-button"
                    onClick={() => this.confirmActivation()}
                />,
                undefined
            );
            return;
        } else {
            await this.props.loading();
            await this.setStatus('Staging');
            if (this.props.match?.params?.id) {
                if (this.props.loadUserData) {
                    await this.props.loadUserData();
                } else if (this.props.loadUser) {
                    await this.props.loadUser(this.props.match.params.id.split('?')[0]);
                }
            }
            if (this.props.onUpdate) {
                await this.props.onUpdate();
            }

            this.props.setAlert(null, undefined);
            this.props.loaded();
        }
        this.setState({ toggleState: selection });
    };

    toggleDeactivation = async () => {
        const newDeactivationState = !this.state.isDeactivated;
        this.props.loading(320, 'toggleDeactivation');
        const res = await api.patch(
            `/users/${this.props.relevantUser?._id}`,
            { is_deactivated: newDeactivationState },
            {
                400: (res: { message: string }) => {
                    setDismissableAlert(this.props.setAlert, res.message, true, 10000);
                },
            }
        );
        this.props.loaded('toggleDeactivation');
        if (res) {
            this.setState({ isDeactivated: newDeactivationState });
        }
    };

    showCreateAccountModal = () => {
        const p = this.props;
        p.openDrawer(() => (
            <AddEditAccountDrawer
                userId={p.relevantUser?._id}
                accountId={undefined}
                close={p.closeDrawer}
                setAlert={(message: string | JSX.Element, isError?: boolean, duration?: number) => {
                    p.setAlert(message, undefined);
                }}
                loaded={p.loaded}
                loading={p.loading}
                reloadData={p.reloadData ? p.reloadData : () => Promise.resolve()}
                vue={p.vue}
            />
        ));
    };

    renderCreateSection = () => {
        const p = this.props;
        const s = this.state;

        return (
            <div
                onClick={() => this.setState({ openCreateSection: !s.openCreateSection })}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                }}
            >
                <div className={'plus-icon'} />
                <span style={{ fontSize: '16px' }}>New</span>

                {s.openCreateSection && (
                    <div
                        className={'supertoolCreateSection'}
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            top: '57px',
                            left: '-14px',
                            backgroundColor: 'white',
                            border: '1px solid #ddd',
                            padding: '10px',
                            paddingRight: '15px',
                            boxShadow: 'var(--box-shadow)',
                            borderRadius: '5px',
                        }}
                    >
                        {UserPermissions().canCreateAccounts && (
                            <div
                                id={'supertoolAddEntity'}
                                className={'supertoolCreate'}
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    this.showCreateAccountModal();
                                }}
                            >
                                <div className={'plus-icon'} />
                                <span>Entity</span>
                            </div>
                        )}

                        {UserPermissions().canCreateConnections && (
                            <div
                                id={'supertoolAddInvestment'}
                                className={'supertoolCreate'}
                                onClick={() =>
                                    p.openDrawer(() => (
                                        <AddEditConnectionDrawer
                                            mode={'create'}
                                            close={p.closeDrawer}
                                            relevantUser={p.relevantUser}
                                            accounts={p.accounts}
                                            reloadData={this.props.reloadData}
                                            loading={p.loading}
                                            loaded={p.loaded}
                                            setAlert={p.setAlert}
                                            adminOrAdvisorVue={true}
                                            vue={this.props.vue}
                                        />
                                    ))
                                }
                            >
                                <div className={'plus-icon'} />
                                <span>Connection</span>
                            </div>
                        )}

                        {UserPermissions().canCreatePortalInvestments && (
                            <div
                                id={'supertoolAddInvestment'}
                                className={'supertoolCreate'}
                                onClick={() =>
                                    p.openDrawer(() => (
                                        <AddInvestmentDrawer
                                            close={p.closeDrawer}
                                            user={p.relevantUser!}
                                            reloadData={this.props.reloadData}
                                            loading={p.loading}
                                            loaded={p.loaded}
                                            setAlert={p.setAlert}
                                            firm={p.firm}
                                            advisor={p.advisor}
                                        />
                                    ))
                                }
                            >
                                <div className={'plus-icon'} />
                                <span>Investment</span>
                            </div>
                        )}

                        {UserPermissions().canCreateManualInvestments && (
                            <div
                                id={'supertoolAddInvestment'}
                                className={'supertoolCreate'}
                                onClick={() => {
                                    this.props.closeDrawer();
                                    if (this.props.history && this.props.match?.params?.id) {
                                        const path = `/${this.props.user?.access === 'advisor' ? 'advisor/clients' : 'admin/users'}/${
                                            this.props.match.params.id.split('?')[0]
                                        }/addManual`;
                                        this.props.history.push(path);
                                    }
                                }}
                            >
                                <span className={'plus-icon'} />
                                <span style={{ display: 'flex', flexDirection: 'column', maxHeight: '60px' }}>
                                    <span>Manual Investment</span>
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render = () => {
        const s = this.state;
        const p = this.props;
        return (
            <>
                <div className="supertoolContainer">
                    <div className="supertoolItems">
                        {p.user?.access === 'admin' && (
                            <>
                                <div className="a supertoolCreate" onClick={() => api.loginAs(p.relevantUser?._id)}>
                                    Login
                                </div>
                                <div className="supertoolSeparator"></div>
                            </>
                        )}

                        {this.props.vue === VUES.ADMIN && (
                            <ReSLAButton userId={p.relevantUser?._id} setAlert={async (alert) => p.setAlert(alert, false)} loaded={p.loaded} loading={p.loading} />
                        )}

                        <div className="supertoolSeparator"></div>

                        <div className={'supertoolUserStatusBar'}>
                            <Multitoggle
                                keyStr={'userStatusToggle'}
                                options={['Staging', 'Active'].map((s) => rephraseClientStatus(s))}
                                selection={rephraseClientStatus(this.state.toggleState || '')}
                                zIndex={2000}
                                onSelect={(s) => this.toggleState(rephraseClientStatus(s, true))}
                                disabled={!UserPermissions().canSetUserStagingActive}
                                optionStyle={{ cursor: UserPermissions().canSetUserStagingActive ? 'pointer' : 'default' }}
                            />
                        </div>

                        {UserPermissions()?.featureLocks?.setUserStagingActive && (
                            <>
                                <span
                                    className="show-tooltip"
                                    onMouseEnter={() => this.setState({ tooltips: { stagingActiveToggle: true } }, () => console.log('tooltip set'))}
                                    onMouseLeave={() => this.setState({ tooltips: { stagingActiveToggle: false } }, () => console.log('tooltip unset'))}
                                >
                                    <img style={{ width: '15px' }} src="/images/icons/lock_black.png" alt="Lock icon" />
                                </span>
                                {s.tooltips?.stagingActiveToggle && (
                                    <div className="popper" style={{ marginLeft: '-50px', marginTop: '155px' }}>
                                        The ability to set a user to Active is a locked premium feature. Please upgrade your account to unlock this feature.
                                    </div>
                                )}
                            </>
                        )}

                        <div className="supertoolSeparator"></div>

                        <div className={'supertoolUserDeactivationBar'}>
                            <Multitoggle
                                keyStr={'userDeactivationToggle'}
                                options={['Active', 'Deactivated']}
                                selection={this.state.isDeactivated ? 'Deactivated' : 'Active'}
                                zIndex={2000}
                                onSelect={() => this.toggleDeactivation()}
                                disabled={!UserPermissions().canSetUserStagingActive}
                                optionStyle={{ cursor: UserPermissions().canSetUserStagingActive ? 'pointer' : 'default' }}
                            />
                        </div>

                        <div className="supertoolSeparator"></div>

                        {this.renderCreateSection()}
                    </div>
                </div>
            </>
        );
    };
}
