import { faRefresh, faThumbTack, faThumbTackSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import '../../styles/paginationFooter.css';
import IconButton from '../Buttons/IconButton';
import Dropdown from '../Dropdowns/Dropdown';
import DelayedInput from '../Inputs/DelayedInput';

const isMobile = window.innerWidth <= 768;

interface PaginationFooterProps {
    total: number;
    rowsPerPage: number;
    page: number;
    forceSeeAll: boolean;
    numRowsOptions: number[];
    dropdownHeight?: string;
    defaultRowsPerPage?: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rows: number) => void;
    refreshData?: () => void;
    useStickyHeader?: boolean;
    setUseStickyHeader?: (useStickyHeader: boolean) => void;
}

const PaginationFooter: React.FC<PaginationFooterProps> = ({
    total,
    rowsPerPage,
    page,
    forceSeeAll,
    numRowsOptions,
    dropdownHeight,
    defaultRowsPerPage,
    setPage,
    setRowsPerPage,
    refreshData,
    useStickyHeader = undefined,
    setUseStickyHeader = undefined,
}) => {
    const [numPages, setNumPages] = useState(0);
    const [hasMorePages, setHasMorePages] = useState(false);
    const [pageNumberDisplay, setPageNumberDisplay] = useState(0);

    useEffect(() => {
        const calculatedNumPages = total === 0 ? 0 : Math.ceil(total / rowsPerPage);
        setNumPages(calculatedNumPages);
        setHasMorePages(page < calculatedNumPages - 1);
        setPageNumberDisplay(calculatedNumPages ? page + 1 : 0);
    }, [page, rowsPerPage, total]);

    const renderPageRange = () => {
        const start = page * rowsPerPage + 1;
        let end = pageNumberDisplay * rowsPerPage;
        end = forceSeeAll ? total : Math.min(end, total);
        return <div className="pagFooter_footRange" style={{ whiteSpace: 'nowrap' }}>{`${start}-${end} of ${total}`}</div>;
    };

    const handleSetRowsPerPage = (rows: number | string) => {
        const rowsNumber = rows === 'All' ? -1 : Number(rows);
        if (rowsNumber === rowsPerPage) return;
        if (page > 0) {
            const currentRange = page * rowsPerPage;
            let newPage = 1;
            if (currentRange < rowsNumber) {
                newPage = 0;
            } else if (currentRange > rowsNumber) {
                newPage = Math.floor((page * rowsPerPage) / rowsNumber);
            }
            setPage(newPage);
        }
        setRowsPerPage(rowsNumber);
        const calculatedNumPages = total === 0 ? 0 : Math.ceil(total / rowsNumber);
        setNumPages(calculatedNumPages);
        setHasMorePages(page < calculatedNumPages - 1);
        setPageNumberDisplay(calculatedNumPages ? page + 1 : 0);
    };

    const renderRefreshButton = () => {
        if (!refreshData) return null;
        return (
            <FontAwesomeIcon
                icon={faRefresh}
                title="Refresh Table Data"
                className="ral_refreshIcon a"
                style={{ marginLeft: '20px', width: '22px', height: '22px' }}
                onClick={async (e) => {
                    e.stopPropagation();
                    refreshData?.();
                }}
            />
        );
    };

    const renderStickyHeaderButton = () => {
        if (!setUseStickyHeader) return null;
        return (
            <IconButton
                faIcon={useStickyHeader ? faThumbTack : faThumbTackSlash}
                title={useStickyHeader ? 'Unpin Table Header' : 'Pin Table Header'}
                color={useStickyHeader ? undefined : 'var(--altx-gray)'}
                onClick={() => setUseStickyHeader?.(!useStickyHeader)}
            />
        );
    };

    return (
        <div
            className="pagFooter_footContainer"
            style={{
                ...(isMobile ? { marginBottom: '160px' } : {}),
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            {!forceSeeAll && (
                <>
                    <span style={{ whiteSpace: 'nowrap' }}>Rows per page:&emsp;</span>
                    <Dropdown
                        dataTestId="rowsPerPage"
                        disableDefaultOption={true}
                        idPrefix="rowsPerPage"
                        options={numRowsOptions.map((num) => (num < 0 ? 'All' : num))}
                        select={handleSetRowsPerPage}
                        style={{ width: '65px', height: dropdownHeight ?? '30px' }}
                        defaultSelection={defaultRowsPerPage ?? rowsPerPage}
                    />
                </>
            )}
            {renderPageRange()}

            {!forceSeeAll && (
                <>
                    <div className={'pagFooter_footPageBack' + (page !== 0 ? ' pagFooter_link' : '')}>
                        <img
                            src={`/images/icons/leftCaret${page !== 0 ? '' : 'Dim'}.png`}
                            alt="previous page"
                            onClick={() => {
                                if (page === 0) return;
                                setPage(page - 1);
                                setPageNumberDisplay(page);
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <DelayedInput
                            className="pagFooter_footPageNumber"
                            containerStyle={{
                                width: `${20 + Math.ceil(Math.log10(numPages + 1)) * 10}px`,
                            }}
                            inputStyle={{ height: '20px' }}
                            type="text"
                            value={pageNumberDisplay.toString()}
                            onChange={(value) => {
                                const valueNumber = parseInt(value, 10);
                                if (isNaN(valueNumber) || valueNumber < 0 || valueNumber > numPages) {
                                    return;
                                }
                                const newPage = valueNumber - 1;
                                const maxPage = numPages - 1;
                                const pageNum = Math.max(0, Math.min(maxPage, newPage));
                                setPage(pageNum);
                                setPageNumberDisplay(pageNum + 1);
                            }}
                            delay={700}
                        />
                        <div className="pagFooter_footPageDivider">/</div>
                        <div className="pagFooter_footPageNumber">{numPages}</div>
                    </div>
                    <div className={'pagFooter_footPageForward' + (hasMorePages ? ' pagFooter_link' : '')}>
                        <img
                            src={`/images/icons/rightCaret${hasMorePages ? '' : 'Dim'}.png`}
                            alt="next page"
                            onClick={() => {
                                if (!hasMorePages) return;
                                setPage(page + 1);
                                setPageNumberDisplay(page + 2);
                            }}
                        />
                    </div>
                </>
            )}

            {renderStickyHeaderButton()}

            {renderRefreshButton()}
        </div>
    );
};

export default PaginationFooter;
