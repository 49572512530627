import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import formatCurrency from '../../utilities/format/formatCurrency';
import emptyStateData from '../EmptyStates/emptyStateFakeData';
import EmptyOverlay from '../EmptyStates/EmptyOverlay';
import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';
import api from '../../api';

import '../../styles/widget.css';
import '../../styles/widget-Distributions.css';
import { CURRENCIES } from '../../constants/currencyConstants';
import { toQueryString } from '../../utilities/apiHelpers/queryString';
import { getGlobalInvestmentFilterQuery, subscribeGlobalInvestmentFilterChange } from '../../common/getSetGlobalInvestmentFilter';

interface DistributionType {
    type: string;
    distributions: number;
}

interface YearData {
    _id: string;
    total: number;
    types: DistributionType[];
}

interface Props {
    userId?: string;
    account?: {
        _id: string;
    };
    investments?: any[];
    vue?: string;
    loading: (progress: number, key: string) => void;
    loaded: (key: string) => void;
    setHeight?: (height: number) => void;
    containerStyle?: React.CSSProperties;
}

// Add this type for the MouseEvent
interface ExtendedMouseEvent extends MouseEvent {
    layerX: number;
    layerY: number;
}

const Distributions: React.FC<Props> = ({ userId, account, investments, vue = '', loading, loaded, setHeight, containerStyle }) => {
    const [nYearsToShow] = useState(2);
    const [showPopper, setShowPopper] = useState(false);
    const [popperText, setPopperText] = useState('');
    const [popperPosition, setPopperPosition] = useState({ left: 0, top: 0 });
    const [currentHeight, setCurrentHeight] = useState(0);
    const [dollarByTypeByYearList, setDollarByTypeByYearList] = useState<YearData[]>([]);
    const [maxTotal, setMaxTotal] = useState(0);
    const [relevantTypes, setRelevantTypes] = useState<string[]>([]);
    const [showEmptyState, setShowEmptyState] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [colorMap, setColorMap] = useState<Record<string, string>>({});

    const containerRef = useRef<HTMLDivElement>(null);

    const loadData = async () => {
        if (!userId) return;

        loading(0, 'distributions_loadData');

        try {
            const queryObj = {
                userId,
                ...(account?._id ? { account: account._id } : {}),
                ...getGlobalInvestmentFilterQuery(vue),
            };

            const investmentRoute = `/investments/taxdistributions?${toQueryString(queryObj)}`;
            const response = await api.get(investmentRoute);

            if (!response) {
                throw new Error('Failed to load distributions');
            }

            // construct relevant data
            let investmentTypes = response.types ?? [];
            let yearList = response.results?.length > 0 ? response.results.filter((_: any, dIdx: number) => dIdx < nYearsToShow) : [];
            let total = Math.max(...yearList.map((dbt: YearData) => dbt.total));
            let types = investmentTypes.filter((type: string) => yearList.some((dbt: YearData) => dbt.types.some((t) => t.type === type && t.distributions > 0)));

            // If there are no distributions, show the empty state
            const isEmpty = yearList.length === 0;
            if (isEmpty) {
                yearList = emptyStateData.DocumentsWidget.distributions.dollarByTypeByYearList;
                total = emptyStateData.DocumentsWidget.distributions.maxTotal;
                types = emptyStateData.DocumentsWidget.distributions.relevantTypes;
                investmentTypes = types;
            }

            // sort each year's types by distributions
            yearList.forEach((result: YearData) => result.types.sort((a, b) => b.distributions - a.distributions));

            // build color map
            const colors = investmentTypes.reduce((acc: Record<string, string>, obj: string, idx: number) => {
                acc[obj] = getColorFromIndex(idx);
                return acc;
            }, {});

            setDollarByTypeByYearList(yearList);
            setShowEmptyState(isEmpty);
            setColorMap(colors);
            setMaxTotal(total);
            setRelevantTypes(types);
        } catch (err) {
            setError((err as Error).message);
        } finally {
            loaded('distributions_loadData');
        }
    };

    useEffect(() => {
        const unsubscribe = subscribeGlobalInvestmentFilterChange(() => loadData());
        loadData();
        return () => unsubscribe?.();
    }, [userId]);

    useEffect(() => {
        if (!_.isEqual(userId, userId) || !_.isEqual(account?._id, account?._id) || !_.isEqual(investments, investments)) {
            loadData();
        }
    }, [userId, account?._id, investments]);

    useEffect(() => {
        const height = containerRef.current?.clientHeight;
        if (height && height !== currentHeight) {
            setCurrentHeight(height);
            const heightWithoutPadding = height - 40;
            setHeight?.(heightWithoutPadding);
        }
    }, [containerRef.current?.clientHeight]);

    const handlePopper = (show: boolean, key: string, amount: number, event: React.MouseEvent) => {
        event.persist();
        // Cast the nativeEvent to our extended type
        const nativeEvent = event.nativeEvent as ExtendedMouseEvent;
        setShowPopper(show);
        setPopperText(show ? `${key}: ${formatCurrency(amount)}` : '');
        setPopperPosition({
            left: show ? nativeEvent.layerX - 100 : 0,
            top: show ? nativeEvent.layerY : 0,
        });
    };

    const renderBar = (yearData: YearData, maxTotal: number) => (
        <div key={`dist_${yearData._id}`} style={{ width: '100%' }}>
            <h3 data-testid={`year-${yearData._id}`}>
                {yearData._id} ({formatCurrency(yearData.total)})
            </h3>
            <div style={{ display: 'flex', backgroundColor: 'var(--color-lighter-gray)' }}>
                {yearData.types.length ? (
                    yearData.types.map((dbt) => (
                        <div
                            key={dbt.type}
                            className="dist-bar-filled"
                            data-testid="distribution-bar"
                            onMouseMove={(e) => handlePopper(true, dbt.type, dbt.distributions, e)}
                            onMouseLeave={(e) => handlePopper(false, dbt.type, dbt.distributions, e)}
                            style={{
                                width: `${(maxTotal === 0 ? 0 : dbt.distributions / maxTotal) * 100}%`,
                                background: colorMap[dbt.type],
                            }}
                        />
                    ))
                ) : (
                    <div className="dist-bar-filled" />
                )}
            </div>
        </div>
    );

    const renderLegend = (types: string[]) =>
        types.map((dbt) => (
            <div className="legend-item" data-testid="legend-item" key={dbt}>
                <div style={{ background: colorMap[dbt] }} />
                <label>{dbt}</label>
            </div>
        ));

    return (
        <EmptyOverlay
            isEmpty={showEmptyState}
            emptyText={emptyStateData.DocumentsWidget.emptyText}
            textStyle={emptyStateData.DocumentsWidget.textStyle}
            data-testid="empty-overlay-component"
        >
            <div ref={containerRef} className="distributions-container widget" style={containerStyle}>
                {error ? (
                    <div className="error-container">
                        <span className="error-message">{error}</span>
                    </div>
                ) : (
                    <div className="distributions-chart-container">
                        <div className="distributions-header">
                            <img src="/images/icons/coin.png" alt="coin" />
                            <h1>Year over Year Distributions</h1>
                            <span>{formatCurrency(dollarByTypeByYearList[0]?.total ?? 0, CURRENCIES.USD, 0)}</span>
                        </div>

                        {dollarByTypeByYearList.map((dbt, dbtIdx) => (
                            <div className="dist-bar-container" key={`distributionBar_${dbtIdx}`}>
                                <div className="dist-bars-filled-container" key="dist-bars">
                                    {renderBar(dbt ?? {}, maxTotal)}
                                </div>
                            </div>
                        ))}

                        {showPopper && (
                            <div
                                className="popper"
                                style={{
                                    marginTop: '20px',
                                    padding: '10px',
                                    position: 'absolute',
                                    left: `${popperPosition.left}px`,
                                    top: `${popperPosition.top}px`,
                                }}
                            >
                                {popperText}
                            </div>
                        )}

                        <div className="dist-legend" key="dist-legend">
                            {renderLegend(relevantTypes ?? [])}
                        </div>
                    </div>
                )}
            </div>
        </EmptyOverlay>
    );
};

export default Distributions;
