import * as openApiClientLib from './openApiClient/index';
import { UserPermissions } from './utilities/AdvisorVue/permissions';

// pass in two enums, which come from Swagger as key/value objects
// the result is a matching of values from the first one as KEYS, and values from the second one as VALUES
export const combineEnumValues = (keys: string[], values: string[]): { [key: string]: string } => {
    if (keys.length !== values.length) {
        console.log('Keys and values must be the same length');
        return {};
    }
    const combined: { [key: string]: string } = {};
    keys.forEach((key, keyIdx) => {
        combined[key] = values[keyIdx];
    });
    return combined;
};

const additionalEnums = {
    AssetManagerConnectionOutreachStatuses: {
        all_statuses: Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesAllStatusesEnum),
        incomplete_statuses: Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesIncompleteStatusesEnum),
        complete_statuses: Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesCompleteStatusesEnum),
        phrasing_map: combineEnumValues(
            Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesPhrasingMapKeysEnum),
            Object.values(openApiClientLib.AssetManagerConnectionOutreachStatusesPhrasingMapValuesEnum)
        ),
    },
    TransactionTypes: {
        typeArr: Object.values(openApiClientLib.TransactionTypesTypeArrEnum).map((t) => JSON.parse(t)),
        typeMap: combineEnumValues(
            Object.values(openApiClientLib.TransactionTypesTypeMapKeysEnum),
            Object.values(openApiClientLib.TransactionTypesTypeMapValuesEnum).map((t) => JSON.parse(t))
        ),
    },
    AllTransactionTypes: {
        typeArr: Object.values(openApiClientLib.TransactionTypesTypeArrEnum).map((t) => JSON.parse(t)),
        typeMap: combineEnumValues(
            Object.values(openApiClientLib.TransactionTypesTypeMapKeysEnum),
            Object.values(openApiClientLib.TransactionTypesTypeMapValuesEnum).map((t) => JSON.parse(t))
        ),
    },
};

// remove fees from transaction types if hide_fees is true for the investor or advisor
// "AllTransactionTypes" contains all transaction types, including removed types
if (UserPermissions().hide_fees) {
    const isFee = (t: string) => t.split('_').includes('fee');
    additionalEnums.TransactionTypes.typeArr = additionalEnums.TransactionTypes.typeArr.filter((t) => !isFee(t.transaction_type));
    additionalEnums.TransactionTypes.typeMap = Object.fromEntries(Object.entries(additionalEnums.TransactionTypes.typeMap).filter(([key, value]) => !isFee(key)));
}

export default additionalEnums;
