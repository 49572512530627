import React from 'react';
import { DrawerInputObject } from './DrawerInputObject';
import { DrawerInputObject2 } from './DrawerInputObject2';
// import { compact } from 'lodash';

interface InputObject {
    fieldName: string;
    calculateFromData?: (data: any) => any;
    renderRightComponent?: React.ReactNode;
    [key: string]: any;
}

interface Errors {
    [key: string]: string | string[] | undefined;
}

interface StateData {
    [key: string]: any;
}

// used for commonData and metadata
// fieldDataStructure: list of component input objects
// stateField: [string] field in the state where the data is located
// stateData: [object] the current state data (ie. state[stateField])
// data: object - each has {label, type, value, placeholder, options}
export const renderInputComponentList = <T extends object>(
    fieldDataStructure: InputObject[] | { [key: string]: InputObject },
    setValue: (fieldName: keyof T, value: T[keyof T]) => void,
    stateData: StateData = {},
    errors: Errors = {},
    notateRequired: boolean = false,
    readOnly: boolean = false,
    useObject2: boolean = false,
    inlineLabel: boolean = false,
    compactMode: boolean = false,
    customClassName: string = '',
    setStateData: (data: StateData) => void = () => {}
): React.ReactNode[] => {
    return Object.values(fieldDataStructure).map((obj: InputObject, objIdx: number) => {
        if (useObject2) {
            return (
                <div key={'inputComponent' + objIdx} style={{ position: 'relative' }} className={customClassName}>
                    <DrawerInputObject2
                        object={obj}
                        onChangeCallback={(value: T[keyof T]) => {
                            setValue(obj.fieldName as keyof T, value);
                        }}
                        stateData={stateData}
                        setStateData={setStateData}
                        value={obj.calculateFromData?.(stateData) ?? stateData[obj.fieldName]}
                        errors={errors}
                        notateRequired={notateRequired}
                        readOnly={readOnly}
                        inlineLabel={inlineLabel}
                        compactMode={compactMode}
                        renderRightComponent={obj?.renderRightComponent}
                    />
                </div>
            );
        } else {
            return (
                <div key={'inputComponent' + objIdx} style={{ position: 'relative' }} className={customClassName}>
                    <DrawerInputObject
                        object={obj}
                        onChangeCallback={(value: T[keyof T]) => {
                            setValue(obj.fieldName as keyof T, value);
                        }}
                        value={obj.calculateFromData?.(stateData) ?? stateData[obj.fieldName]}
                        errors={errors}
                        notateRequired={notateRequired}
                        readOnly={readOnly}
                        inlineLabel={inlineLabel}
                        compactMode={compactMode}
                    />
                </div>
            );
        }
    });
};
