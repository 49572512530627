import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { EM_DASH, VUES } from '../../constants/constantStrings';
import { calcMoic } from '../../utilities/calculate/financeCalculations';
import formatCurrency from '../../utilities/format/formatCurrency';
import PaginationTable from './PaginationTable';

import { getInvestmentDate } from '../../common/investments/getInvestmentDate';
import { getCurrencyString } from '../../constants/currencyConstants';
import '../../styles/widget-investmentDetails.css';
import '../../styles/widget.css';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { toQueryString } from '../../utilities/apiHelpers/queryString';

const isMobile = window.innerWidth <= 768;

class InvestmentDetailsPerformanceTable extends Component {
    callables = null;
    state = {
        expanded: new Set(),
        sortState: null,
    };

    rowInfo = {
        rowClick: (dataItems, idx) => {
            if (!UserPermissions().canReadAnyInvestments) return;
            // go to investment details page
            if (this.props.advisorVue) {
                const pathPrefix = this.props.user.access === 'advisor' ? '/advisor/clients' : '/admin/users';
                this.props.history.push(`${pathPrefix}/${this.props.match.params.id}/details/${dataItems[idx]._id}`);
            } else {
                this.props.history.push(`/accounts/${this.props.account?._id ?? 'all'}/investments/${dataItems[idx]._id}/details`);
            }
        },
    };

    onSort = (sortState) => {
        this.setState({ sortState });
    };

    sortData = (data) => {
        const s = this.state;
        if (s.sortState && (s.sortState.field || s.sortState.func)) {
            if (!s.sortState.func) {
                const field = s.sortState.field;
                const fieldList = field.split('.');
                const asc = !!s.sortState.ascending;

                const reduceFields = (obj, fields) => {
                    if (!obj[fields[0]]) {
                        return null;
                    } else if (fields.length === 1) {
                        return obj[fields[0]];
                    } else {
                        return reduceFields(obj[fields[0]], fields.slice(1));
                    }
                };

                const sortFunc = (a, b) => {
                    const aField = reduceFields(a, fieldList);
                    const bField = reduceFields(b, fieldList);
                    if (aField === null && bField !== null) return asc ? -1 : 1;
                    if (bField === null && aField !== null) return asc ? 1 : -1;
                    if (aField === null && bField === null) return 0;
                    return (aField < bField && asc) || (aField > bField && !asc) ? -1 : 1;
                };
                data = data.sort(sortFunc);
            } else {
                data = data.sort((a, b) => s.sortState.func(a) - s.sortState.func(b));
            }
        }
        return data;
    };

    getVintageYear = (inv) => {
        let date = getInvestmentDate(inv);
        date = date ? date.getFullYear() : null;
        return date;
    };

    getCommitmentValue = (inv) => inv?.performance?.itd?.committed ?? null;

    getPaidInValue = (inv) => inv?.performance?.itd?.contributions ?? null;

    getUnfundedValue = (inv) => {
        return inv?.performance?.itd?.unfunded_to_date;
    };

    getDistributionValue = (inv) => inv?.performance?.itd?.distributions ?? null;

    getCurrentValue = (inv) => inv?.valuation ?? null;

    getDpiValue = (inv) => {
        const dpi = (inv?.performance?.itd?.distributions / inv?.performance?.itd?.contributions) * 100;
        return isFinite(dpi) ? dpi.toFixed(2) : null;
    };

    getIRRValue = (inv) => {
        let value = inv?.performance?.itd?.irr ? inv?.performance?.itd?.irr * 100 : null;

        if (value && value > 1000000) {
            value = Number(value.toExponential().split('e+')[0]).toFixed(1) + 'e+' + value.toExponential().split('e+')[1];
        } else {
            value = value ? `${value.toFixed(1)}%` : null;
        }

        return value;
    };

    getCellStyle = () => ({
        textAlign: 'right',
    });

    columns = () => {
        const columns = [
            {
                title: 'SECURITY',
                sort: {
                    field: 'name',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const p = this.props;
                    const inv = dataItems[itemIdx];
                    const key = 'invName' + (inv?._id ?? inv?.name ?? String(itemIdx));

                    const path = p.vue === VUES.INVESTOR ? `/accounts/${inv.account}/investments/${inv._id}/details` : `${p.location.pathname}/details/${inv._id}`;

                    const goToInvestment = UserPermissions().canReadAnyInvestments ? () => p.history.push(path) : null;

                    return isMobile ? (
                        <div style={{}} className="altx-mobile-table-cell" key={key}>
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <label>Security</label>
                                <span className="a" onClick={goToInvestment}>
                                    {inv?.name}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <td style={{ textAlign: 'left' }} className="altx-table-cell" key={key} title={inv?.name}>
                            <div>
                                <div style={{ color: 'var(--color-light-gray)', fontSize: '14px' }}>{inv?.account?.name}</div>
                                <div style={{}}>{inv?.name}</div>
                            </div>
                            {/* <span className='a' onClick={goToInvestment}>{inv?.name}</span> */}
                        </td>
                    );
                },
                headerStyle: { textAlign: 'left' },
            },
            {
                title: 'VINTAGE YEAR',
                sort: {
                    field: 'started_at',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invYear' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let date = this.getVintageYear(inv) ?? EM_DASH;
                    date = incomplete ? 'This investment is under administrative review' : date;
                    if (incomplete) {
                        const innerSpan = <span>This investment is under administrative review</span>;
                        return isMobile ? (
                            <div className="altx-mobile-table-cell inv-perf-admin-review-desc" key={key}>
                                <div style={{ marginTop: '20px', paddingBottom: '10px' }}>{innerSpan}</div>
                            </div>
                        ) : (
                            <td className="altx-table-cell inv-perf-admin-review-desc" key={key}>
                                {innerSpan}
                            </td>
                        );
                    }
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Vintage Year</label>
                            {date}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {date}
                        </td>
                    );
                },
            },
            {
                title: 'CURRENCY',
                sort: {
                    field: 'currency',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invCurrency' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = getCurrencyString(inv?.currency) ?? EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Currency</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'COMMITMENT',
                sort: {
                    field: 'performance?.itd?.committed',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invCommitted' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getCommitmentValue(inv);
                    value = value ? formatCurrency(value, inv.currency) : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Commitment</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'PAID IN',
                sort: {
                    field: 'performance?.itd?.contributions',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invPaidIn' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getPaidInValue(inv);
                    value = value ? formatCurrency(value, inv.currency) : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Paid In</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'UNFUNDED',
                sort: {
                    field: 'unfunded',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invUnfunded' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getUnfundedValue(inv);
                    value = value ? formatCurrency(value, inv.currency) : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Unfunded</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'DISTRIBUTIONS',
                sort: {
                    field: 'performance?.itd?.distributions',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invDistributions' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getDistributionValue(inv);
                    value = value ? formatCurrency(value, inv.currency) : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Distributions</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'CURRENT VALUE',
                sort: {
                    field: 'valuation',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invCurValue' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getCurrentValue(inv);
                    value = value ? formatCurrency(value, inv.currency) : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Current Value</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'DPI (Distributed / Invested)',
                sort: {
                    field: 'dpi',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invDpi' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getDpiValue(inv);
                    value = value ? `${value}%` : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>DPI (Distributed / Invested)</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle() }}>
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'IRR',
                sort: {
                    field: 'performance?.itd?.irr',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invIrr' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = this.getIRRValue(inv) ?? EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>IRR</label>
                            {value}
                        </div>
                    ) : (
                        <td
                            className="altx-table-cell"
                            key={key}
                            style={{
                                ...this.getCellStyle(),
                                color: value && value < 0 ? 'var(--color-dark-red)' : 'var(--color-dark-gray)',
                            }}
                        >
                            {value}
                        </td>
                    );
                },
            },
            {
                title: 'MOIC',
                sort: {
                    field: 'moic',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const inv = dataItems[itemIdx];
                    const incomplete = inv.incomplete;
                    const key = 'invMoic' + (inv?._id ?? inv?.name ?? String(itemIdx));
                    let value = calcMoic(inv)?.toFixed(2);
                    value = isFinite(value) ? `${value}x` : EM_DASH;
                    if (incomplete) return null;
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>MOIC</label>
                            {value}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key} style={{ ...this.getCellStyle(), paddingRight: '20px' }}>
                            {value}
                        </td>
                    );
                },
                headerStyle: { paddingRight: '20px' },
            },
        ];
        return columns;
    };

    getRoute = () => {
        const p = this.props;

        const queryObj = {
            populate_account: true,
            investments: p.investments.map((i) => i._id),
        };

        const route = `/investments?${toQueryString(queryObj)}`;

        return route;
    };

    render = () => {
        const p = this.props;

        if (!p.investments || !p.investments.length) return null;

        return (
            <div>
                <div
                    className="widget investment-details-performance-table"
                    // id={isMobile ? 'mobile-investment-details-table' : 'investment-details-table'}
                    style={{ padding: '20px', backgroundColor: 'white' }}
                >
                    <PaginationTable
                        setCallables={(callables) => {
                            this.callables = callables;
                            if (p.setCallables) p.setCallables(callables);
                        }}
                        containerStyle={{ margin: 0 }}
                        // noHeader={true}
                        // noFooter={true}
                        noInvestmentDropdown={p.noInvestmentDropdown}
                        disableDefaultOption={true}
                        reload={this.state.reload}
                        title={p.title || 'Investment Performance Details'}
                        loading={p.loading}
                        loaded={p.loaded}
                        setSelectedInvestment={p.setSelectedInvestment}
                        route={this.getRoute()}
                        useApi2={true}
                        getResultsFromResponse={(res) => res.investments}
                        forceSeeAll={true}
                        columns={this.columns()}
                        headerCellTitleStyle={{ fontSize: '14px' }}
                        rowInfo={this.rowInfo}
                        csvTitle={'Documents'}
                        csvHeaders={[
                            'SECURITY',
                            'SECURITY ID',
                            'ENTITY',
                            'ENTITY ID',
                            'VINTAGE YEAR',
                            'COMMITMENT',
                            'PAID IN',
                            'UNFUNDED',
                            'DISTRIBUTIONS',
                            'CURRENT VALUE',
                            'IRR',
                            'MOIC',
                            'DPI',
                        ]}
                        csvRow={(item) => {
                            const moic = calcMoic(item);
                            return [
                                item.name,
                                item._id,
                                item.account?.name ?? EM_DASH,
                                item.account?._id ?? EM_DASH,
                                this.getVintageYear(item) ?? EM_DASH,
                                this.getCommitmentValue(item) ? formatCurrency(this.getCommitmentValue(item), item.currency) : EM_DASH,
                                this.getPaidInValue(item) ? formatCurrency(this.getPaidInValue(item), item.currency) : EM_DASH,
                                this.getUnfundedValue(item) ? formatCurrency(this.getUnfundedValue(item), item.currency) : EM_DASH,
                                this.getDistributionValue(item) ? formatCurrency(this.getDistributionValue(item), item.currency) : EM_DASH,
                                this.getCurrentValue(item) ? formatCurrency(this.getCurrentValue(item), item.currency) : EM_DASH,
                                this.getIRRValue(item) ? `${this.getIRRValue(item)}` : EM_DASH,
                                isFinite(moic) ? `${moic.toFixed(2)}x` : EM_DASH,
                                this.getDpiValue(item) ? `${this.getDpiValue(item)}%` : EM_DASH,
                            ];
                        }}
                        onSort={this.onSort}
                        headerCellStyle={{ textAlign: 'right' }}
                        cellStyle={{ textAlign: 'right' }}
                        initialSortField={p.initialSortField ?? 'started_at'}
                        onReady={p.onReady}
                    />
                </div>
            </div>
        );
    };
}

export default withRouter(InvestmentDetailsPerformanceTable);
