import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import api from '../../api';
import api2 from '../../api2';
import { STATES, VUES } from '../../constants/constantStrings';
import { CreateAccountRequest, CreateAccountRequestAccountTypeEnum, CreateAccountRequestAddressStateEnum, CreateAccountRequestIdTypeEnum } from '../../openApiClient';
import '../../styles/addEditAdvisorDrawer.css';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { ACCOUNT_TYPES, ID_TYPES } from './AddEditAccountDrawer';
import GenericDrawer from './GenericDrawer';

interface Plan {
    _id: string;
    plan_name: string;
}

interface User {
    _id: string;
    access: string;
}

interface Address {
    streetLine1: string;
    streetLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}

interface CommonData {
    access?: string;
    name?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    account_name?: string;
    id_type?: { value: string; name: string };
    id_number?: string;
    account_type?: { value: string; name: string };
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
}

interface Props extends RouteComponentProps {
    user?: User;
    firm?: any;
    advisor?: any;
    loading: (timeout?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData?: () => void;
    close: () => void;
}

interface Errors {
    [key: string]: string;
}

const AddEditUserDrawer: FC<Props> = ({ user, firm, advisor, loading, loaded, setAlert, reloadData, close }) => {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [errors, setErrors] = useState<Errors>({});

    const loadData = async () => {
        const advisoryGroupPlans = await api.get('/advisorygroupplans');
        setPlans(advisoryGroupPlans);
        return { access: 'Investor' };
    };

    useEffect(() => {
        if (!_.isEqual(firm, advisor)) {
            loadData();
        }
    }, [firm, advisor]);

    const getFields = (state: { commonData: CommonData }) => {
        let fields: Record<string, any> = {
            'Full Name': {
                label: 'Full Name',
                placeholder: 'Full Name',
                fieldName: 'name',
                type: 'text',
                required: true,
            },
            'Email Address': {
                label: 'Email Address',
                placeholder: 'Email Address',
                fieldName: 'email',
                type: 'text',
                required: true,
            },
        };

        if (user?.access === 'admin') {
            fields['User Type'] = {
                label: 'User Type (investor and advisor only)',
                placeholder: 'Select User Type',
                type: 'option',
                fieldName: 'access',
                options: ['Investor', 'Admin', ...(plans?.map((p) => p.plan_name) ?? [])],
                optionClearable: false,
                required: true,
            };
        }

        if (state.commonData.access?.toLowerCase() === VUES.INVESTOR) {
            fields = {
                ...fields,
                Spacer: {
                    type: 'spacer',
                },
                'Account Info Label': {
                    type: 'label',
                    text: 'Entity Info Section',
                    subtext: 'If this section is left blank, an entity will be created using the Full Name of the user.',
                },
                Spacer2: {
                    type: 'spacer',
                },
                'Entity Name': {
                    label: 'Entity Name (defaults to Full Name)',
                    placeholder: state.commonData.name ?? 'Default Entity Name',
                    fieldName: 'account_name',
                    type: 'text',
                },
                'ID Type': {
                    placeholder: 'Select ID Type',
                    label: 'ID Type',
                    fieldName: 'id_type',
                    type: 'option',
                    optionField: 'name',
                    options: ID_TYPES,
                    optionClearable: true,
                    value: state.commonData.id_type,
                },
                'ID Number': {
                    placeholder: 'Enter ID Number',
                    label: 'ID Number',
                    fieldName: 'id_number',
                    type: 'text',
                    allowShow: true,
                },
                'Account Type': {
                    placeholder: 'Select Entity Type',
                    label: 'Entity Type',
                    fieldName: 'account_type',
                    type: 'option',
                    optionField: 'name',
                    options: ACCOUNT_TYPES,
                    optionClearable: true,
                    value: state.commonData.account_type,
                },
                'Street Line 1': {
                    placeholder: 'Enter Street Line 1',
                    label: 'Street Line 1',
                    fieldName: 'streetLine1',
                    type: 'text',
                },
                'Street Line 2': {
                    placeholder: 'Enter Street Line 2',
                    label: 'Street Line 2',
                    fieldName: 'streetLine2',
                    type: 'text',
                },
                City: {
                    placeholder: 'Enter City',
                    label: 'City',
                    fieldName: 'city',
                    type: 'text',
                },
                State: {
                    placeholder: 'Enter State eg. CA, NY, etc.',
                    label: 'State',
                    fieldName: 'state',
                    type: 'select',
                    options: STATES,
                    optionClearable: true,
                },
                'Zip Code': {
                    placeholder: 'Enter Zip Code',
                    label: 'Zip Code',
                    fieldName: 'zipCode',
                    type: 'text',
                },
                Country: {
                    placeholder: 'Enter Country',
                    label: 'Country',
                    fieldName: 'country',
                    type: 'select',
                    options: ['US', 'CA'],
                    optionClearable: true,
                },
            };
        }

        return fields;
    };

    const isSafeToSave = (setErrorState: boolean, formData: CommonData) => {
        const newErrors: Record<string, string> = {};

        if (!formData.name?.trim()) {
            newErrors.name = 'Name is required';
        }
        if (!formData.email?.trim()) {
            newErrors.email = 'Email is required';
        }
        // if (!formData.password?.trim()) {
        //     newErrors.password = 'Password is required';
        // }
        // if (formData.password !== formData.confirmPassword) {
        //     newErrors.confirmPassword = 'Passwords do not match';
        // }

        const isSafe = Object.keys(newErrors).length === 0;

        if (setErrorState) {
            setErrors(newErrors);
        }

        return isSafe;
    };

    const handleSubmit = async (commonData: CommonData) => {
        loading(320, 'createUser');

        const createdByAdmin = user?.access === 'admin';

        const body: Record<string, any> = {
            name: commonData.name,
            email: commonData.email?.toLowerCase(),
            password: commonData.password,
            access: createdByAdmin ? commonData.access?.toLowerCase() : 'investor',
        };

        if (createdByAdmin && commonData.access?.toLowerCase() !== 'investor' && commonData.access?.toLowerCase() !== 'admin') {
            const plan = plans?.find((p) => p.plan_name === commonData.access);
            if (!plan) {
                setDismissableAlert(setAlert, 'Error creating user. Invalid plan.', true);
                loaded('createUser');
                return;
            }
            body.access = 'advisor';
            body.advisoryGroupPlan = plan._id;
        }

        const address: Address = {
            streetLine1: commonData.streetLine1 ?? '',
            streetLine2: commonData.streetLine2 ?? '',
            city: commonData.city ?? '',
            state: commonData.state ?? '',
            zipCode: commonData.zipCode ?? '',
            country: commonData.country ?? '',
        };

        const hasAddressInfo = Object.values(address).some((v) => v);

        const accountBody = {
            ...(commonData.account_name ? { name: commonData.account_name } : {}),
            ...(commonData.id_type ? { id_type: commonData.id_type.value } : {}),
            id_number: commonData.id_number,
            ...(commonData.account_type ? { account_type: commonData.account_type.value } : {}),
            ...(hasAddressInfo ? { address } : {}),
        };

        const hasAccountInfo = Object.values(accountBody).some((v) => v);
        if (hasAccountInfo) {
            body.disable_initial_account_creation = true;
            if (!accountBody.name) {
                accountBody.name = commonData.name;
            }
        }

        try {
            const created_user_id = (
                await api2.client.UserApi.createUser({
                    CreateUserRequest: {
                        name: commonData.name!,
                        email: commonData.email!,
                        password: commonData.password && commonData.password.length > 0 ? commonData.password : undefined,
                        access: body.access,
                        disable_initial_account_creation: body.disable_initial_account_creation,
                    },
                })
            ).data.user_id!;

            if (hasAccountInfo) {
                try {
                    const stateCode = address.state.toUpperCase() as CreateAccountRequestAddressStateEnum;

                    const accountRequestBody: CreateAccountRequest = {
                        name: accountBody.name || commonData.name || '',
                        id_type: accountBody.id_type as CreateAccountRequestIdTypeEnum,
                        id_number: accountBody.id_number || '',
                        account_type: accountBody.account_type as CreateAccountRequestAccountTypeEnum,
                        address: hasAddressInfo
                            ? {
                                  streetLine1: address.streetLine1 || undefined,
                                  streetLine2: address.streetLine2 || undefined,
                                  city: address.city || undefined,
                                  state: stateCode,
                                  zipCode: address.zipCode || undefined,
                                  country: address.country || undefined,
                              }
                            : undefined,
                        user: created_user_id,
                    };

                    const createAccountRes = await api2.client.AccountApi.createAccount({
                        CreateAccountRequest: accountRequestBody,
                    });

                    if (!createAccountRes?.data?.success) {
                        throw new Error('Error creating user entity.');
                    }
                } catch (err) {
                    console.error('Error creating account:', err);
                    await api.delete(`/users/${created_user_id}`);
                    setDismissableAlert(setAlert, 'Error creating user entity.', true);
                    loaded('createUser');
                    return;
                }
            }

            setDismissableAlert(setAlert, 'User created.');
            reloadData?.();
            loaded('createUser');
            close();
        } catch (error: any) {
            console.error('Error creating user:', error);
            let errMsg = 'Error creating user.';
            if (error.response?.status === 400 && error.response.data.message) {
                errMsg = error.response.data.message;
            }
            setDismissableAlert(setAlert, errMsg, true);
            loaded('createUser');
            throw error;
        }
    };

    return (
        <GenericDrawer
            title="Add New User"
            actionStr="Create User"
            getFields={getFields}
            onSubmit={handleSubmit}
            close={close}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            errors={errors}
            setErrors={setErrors}
            isSafeToSave={isSafeToSave}
            className="addNewAdvisorToFirm-drawer"
        />
    );
};

export default withRouter(AddEditUserDrawer);
