import React from 'react';
import api2 from '../../api2';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import AuditLogDisplay from '../AuditLogDisplay';
import { AccountSearchFilter, UserSearchFilter, InvestmentSearchFilter, DocumentSearchFilter } from '../Filters/GenericSearchFilter';
import GenericDrawer from './GenericDrawer';

const ValuationDrawer = ({ valuation_id, ...props }) => {
    const [valuationId, setValuationId] = React.useState(null);
    const editing = !!valuationId;

    React.useEffect(() => {
        setValuationId(valuation_id);
    }, [valuation_id]);

    return (
        <GenericDrawer
            key={valuationId}
            title={`${editing ? 'Update' : 'Create'} Valuation`}
            actionStr={`${editing ? 'Update' : 'Create'} Valuation`}
            close={props.closeDrawer}
            loading={props.loading}
            loaded={props.loaded}
            loadData={async () => {
                if (valuationId) {
                    // load valuation if valuationId is provided
                    try {
                        const valuation = (
                            await api2.client.ValuationApi.getValuation({
                                valuation_id: valuationId,
                                populate_user: true,
                                populate_account: true,
                                populate_investment: true,
                                populate_documents: true,
                            })
                        ).data.valuation;

                        return {
                            user: valuation.user,
                            account: valuation.account,
                            investment: valuation.investment,
                            date: valuation.date,
                            amount: valuation.amount,
                            unit_price: valuation.unit_price,
                            documents: valuation.documents,
                        };
                    } catch (err) {
                        console.error('Could not load valuation', err);
                        setDismissableAlert(props.setAlert, 'Could not load valuation', true);
                    }
                } else {
                    const data = {};
                    if (props.investment?._id) {
                        try {
                            const investment = (
                                await api2.client.InvestmentApi.getInvestment({
                                    investment_id: props.investment?._id,
                                    populate_account: true,
                                    populate_investment_master: true,
                                    populate_user: true,
                                })
                            ).data.investment;
                            data.investment = investment;
                            data.account = investment.account;
                            data.user = investment.user;
                        } catch (err) {
                            console.error('Could not load investment', err);
                            setDismissableAlert(props.setAlert, 'Could not load investment', true);
                        }
                    } else if (props.account?._id) {
                        try {
                            const account = (
                                await api2.client.AccountApi.getAccount({
                                    account_id: props.account?._id,
                                    populate_user: true,
                                })
                            ).data.account;
                            data.account = account;
                            data.user = account.user;
                        } catch (err) {
                            console.error('Could not load account', err);
                            setDismissableAlert(props.setAlert, 'Could not load account', true);
                        }
                    } else if (props.relevantUser) {
                        data.user = props.relevantUser;
                    }
                    return data;
                }
            }}
            onSubmit={async (data) => {
                try {
                    if (editing) {
                        await api2.client.ValuationApi.updateValuation({
                            valuation_id: valuationId,
                            UpdateValuationRequest: {
                                investment: data.investment._id,
                                date: data.date,
                                amount: data.amount,
                                unit_price: data.unit_price,
                                documents: data?.documents?.map((d) => d._id),
                            },
                        });
                    } else {
                        const creationId = (
                            await api2.client.ValuationApi.createValuation({
                                CreateValuationRequest: {
                                    investment: data.investment._id,
                                    date: data.date,
                                    amount: data.amount,
                                    unit_price: data.unit_price,
                                    documents: data?.documents?.map((d) => d._id),
                                },
                            })
                        ).data.valuation_id;
                        setValuationId(creationId);
                    }
                    setDismissableAlert(props.setAlert, `Valuation ${editing ? 'updated' : 'created'} successfully`, false);
                    props.reloadData();
                    // props.closeDrawer();
                } catch (err) {
                    console.error('Could not save valuation', err);
                    setDismissableAlert(props.setAlert, 'Could not save valuation', true);
                }
            }}
            delete={
                !valuationId
                    ? null
                    : async () => {
                          try {
                              await api2.client.ValuationApi.deleteValuation({
                                  valuation_id: valuationId,
                              });
                              setDismissableAlert(props.setAlert, 'Valuation deleted successfully', false);
                              props.reloadData();
                              props.closeDrawer();
                          } catch (err) {
                              console.error('Could not delete valuation', err);
                              setDismissableAlert(props.setAlert, 'Could not delete valuation', true);
                          }
                      }
            }
            getFields={(drawerState, setDrawerState) => {
                let fields = {};

                const user = drawerState?.commonData?.user;
                const account = drawerState?.commonData?.account;
                const investment = drawerState?.commonData?.investment;

                fields = {
                    ...fields,
                    User: {
                        label: 'User',
                        type: 'custom',
                        fieldName: 'user',
                        component: (value, onChange) => {
                            return (
                                <UserSearchFilter
                                    defaultLabel="Select User"
                                    selected={value}
                                    onChange={(newUser) => {
                                        if (newUser?._id === user?._id) return;
                                        onChange(newUser);
                                        setDrawerState((prevState) => {
                                            return {
                                                ...prevState,
                                                commonData: {
                                                    ...prevState.commonData,
                                                    user: newUser,
                                                    account: null,
                                                    investment: null,
                                                },
                                            };
                                        });
                                    }}
                                    width={'100%'}
                                    defaultOptions={false}
                                    disabled={!!props.relevantUser || !!props.account || !!props.investment}
                                />
                            );
                        },
                    },
                };

                fields = {
                    ...fields,
                    Account: {
                        label: 'Account',
                        type: 'custom',
                        fieldName: 'account',
                        component: (value, onChange) => (
                            <AccountSearchFilter
                                filter={{
                                    ...(user && { users: [user._id] }),
                                }}
                                selected={value}
                                onChange={(newAccount) => {
                                    if (newAccount?._id === account?._id) return;
                                    onChange(newAccount);
                                    setDrawerState((prevState) => {
                                        return {
                                            ...prevState,
                                            commonData: {
                                                ...prevState.commonData,
                                                account: newAccount,
                                                investment: null,
                                            },
                                        };
                                    });
                                }}
                                width={'100%'}
                                defaultOptions={true}
                                disabled={!user || !!props.account || !!props.investment}
                                excludeDisplayFields={['user']}
                            />
                        ),
                    },
                    Investment: {
                        label: 'Investment',
                        type: 'custom',
                        fieldName: 'investment',
                        component: (value, onChange) => {
                            return (
                                <InvestmentSearchFilter
                                    filter={{
                                        ...(user && { users: [user._id] }),
                                        ...(account && { accounts: [account._id] }),
                                    }}
                                    selected={value}
                                    onChange={(newInvestment) => {
                                        if (newInvestment?._id === investment?._id) return;
                                        onChange(newInvestment);
                                        setDrawerState((prevState) => {
                                            return {
                                                ...prevState,
                                                commonData: {
                                                    ...prevState.commonData,
                                                    account: {
                                                        ...newInvestment.account,
                                                        user: newInvestment.user,
                                                    },
                                                },
                                            };
                                        });
                                    }}
                                    width={'100%'}
                                    defaultOptions={true}
                                    isDisabled={!account || !!props.investment}
                                    excludeDisplayFields={['user']}
                                />
                            );
                        },
                    },
                };

                fields = {
                    ...fields,

                    Date: {
                        label: 'Date',
                        type: 'date',
                        fieldName: 'date',
                        required: true,
                    },
                    Amount: {
                        label: 'Amount',
                        type: 'number',
                        fieldName: 'amount',
                        required: true,
                    },
                    UnitPrice: {
                        label: 'Unit Price',
                        type: 'number',
                        fieldName: 'unit_price',
                        required: false,
                    },
                    Documents: {
                        label: 'Documents',
                        type: 'custom',
                        fieldName: 'documents',
                        component: (value, onChange) => {
                            return (
                                <DocumentSearchFilter
                                    filter={{
                                        ...(user && { users: [user._id] }),
                                        ownership_type: 'Investment',
                                        ownership_ids: [investment?._id],
                                    }}
                                    selected={value}
                                    onChange={(newDocuments) => {
                                        if (newDocuments?._id === investment?._id) return;
                                        onChange(newDocuments);
                                    }}
                                    isMulti={true}
                                    width={'100%'}
                                    defaultOptions={true}
                                    isDisabled={!investment}
                                    excludeDisplayFields={['ownership', 'user']}
                                    vue={props.vue}
                                    linkDocument={true}
                                />
                            );
                        },
                    },
                };

                return fields;
            }}
            extraContent={() => {
                if (valuationId && UserPermissions().access.admin) {
                    return (
                        <div style={{ marginTop: '20px', clear: 'both' }}>
                            <AuditLogDisplay
                                fetchLogs={async () => {
                                    if (!valuationId) return [];
                                    try {
                                        const audit_log = (
                                            await api2.client.ValuationApi.getValuation({
                                                valuation_id: valuationId,
                                                populate_audit_log: true,
                                            })
                                        ).data.valuation.audit_log;
                                        return audit_log;
                                    } catch (err) {
                                        console.error('Error loading audit logs:', err);
                                        return [];
                                    }
                                }}
                            />
                        </div>
                    );
                }
                return null;
            }}
        />
    );
};

export default ValuationDrawer;
