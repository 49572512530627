import { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { VUES } from '../constants/constantStrings';
import '../styles/title.css';
import { UserPermissions } from '../utilities/AdvisorVue/permissions';
import IconTextButton from './Buttons/IconTextButton';
import { AccountSearchFilter, InvestmentSearchFilter } from './Filters/GenericSearchFilter';
import NotificationBell from './NotificationBell';
import GlobalInvestmentFilter from './Toggles/GlobalInvestmentStatusFilter';

// Define a type for the route parameters
interface RouteParams {
    investment?: string;
}

interface TitleProps extends RouteComponentProps<RouteParams> {
    title: string;
    user: { access: string };
    account: { _id: string; name: string } | null;
    investment: { _id: string; name: string } | null;
    relevantUser: { _id: string } | null;
    notifications: Array<{ read: boolean }>;
    showAccountSelector?: boolean;
    renderLogo?: boolean;
    setAccount: (account: { _id: string; name: string } | null) => void;
    setInvestment: (investment: { _id: string; name: string } | null) => void;
    setViewNotifications: () => void;
    vue: string;
}

const Title: FC<TitleProps> = ({
    title,
    user,
    account,
    investment,
    relevantUser,
    notifications = [],
    showAccountSelector = false,
    renderLogo = false,
    setAccount,
    setInvestment,
    setViewNotifications,
    vue,
    history,
    location,
    match,
}) => {
    // const [expandAccounts, setExpandAccounts] = useState(false);
    // const [expandInvestments, setExpandInvestments] = useState(false);
    // const [viewNotifications, setViewNotificationsState] = useState(false);

    const byInvestmentSupported = () => {
        const routes = ['investments', 'calls', 'transactions'];
        const segments = location.pathname.split('/');
        if (segments.length < 2) return false;
        if (segments[1] !== 'accounts') return false;

        if (segments.length > 5 && segments[3] === 'investments' && routes.includes(segments[5])) return true;
        if (segments.length > 3 && routes.includes(segments[3])) return true;

        return false;
    };

    const byAccountsSupported = () => {
        const onProfile = location.pathname?.includes('profile');
        return user.access !== 'admin' && !onProfile;
    };

    const renderAddEntityOption = () => (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                width: '100%',
                cursor: 'unset',
            }}
            className="select-account-new1"
            onClick={(e) => {
                e.stopPropagation();
                history.push({
                    pathname: `/profile/${account?._id ?? 'all'}`,
                    state: { addEntity: true },
                });
            }}
        >
            <IconTextButton
                icon="/images/icons/plus.png"
                containerStyle={{
                    marginLeft: 0,
                    cursor: 'unset',
                }}
            />
            Add Investment Entity
        </div>
    );

    const setNewInvestment = (newInvestment: { _id: string; name: string } | null) => {
        setInvestment(newInvestment);

        if (vue === VUES.INVESTOR) {
            const segments = location.pathname.split('/') ?? [];
            if (segments.length > 2) {
                const investmentId = match.params.investment;
                if (!newInvestment?._id) {
                    const newPath = [...segments.slice(0, 3), ...segments.slice(-1)].join('/');
                    history.push(newPath);
                } else if (investmentId) {
                    const newPath = location.pathname.replace(investmentId, newInvestment._id);
                    history.push(newPath);
                } else {
                    const newPath = [...segments.slice(0, 3), 'investments', newInvestment._id, ...segments.slice(-1)].join('/');
                    history.push(newPath);
                }

                if (!newInvestment || investmentId === newInvestment._id) return;
                const path = location.pathname.replace(investmentId ?? '', newInvestment._id);
                history.push(path);
            }
        }
    };

    const renderAccountSelect = () => {
        if (!relevantUser) return null;

        return (
            <div>
                <AccountSearchFilter
                    filter={{
                        users: [relevantUser._id],
                        ...(account && { accounts: [account._id] }),
                    }}
                    selected={account}
                    onChange={(account) => setAccount(account)}
                    defaultOptions={true}
                    width={'250px'}
                    excludeDisplayFields={['user']}
                    additionalOptions={vue === VUES.INVESTOR ? [renderAddEntityOption] : []}
                />
            </div>
        );
    };

    const renderInvestmentSelect = () => {
        if (!relevantUser) return null;

        return (
            <div>
                <InvestmentSearchFilter
                    filter={{
                        users: [relevantUser._id],
                        ...(account && { accounts: [account._id] }),
                    }}
                    selected={Array.isArray(investment) ? investment[0] : investment || undefined}
                    onChange={(investment) => setNewInvestment(Array.isArray(investment) ? investment[0] : investment)}
                    defaultOptions={true}
                    width={'250px'}
                    excludeDisplayFields={[...(vue === VUES.INVESTOR ? (['ownership'] as const) : [])]}
                    aria-label="investment selector"
                />
            </div>
        );
    };

    const renderInvestorRightHeader = () => {
        const showSelectInvestment = byInvestmentSupported();
        const showSelectAccount = byAccountsSupported() && showAccountSelector;

        return (
            <>
                {showSelectInvestment && renderInvestmentSelect()}
                {showSelectAccount && renderAccountSelect()}
                <GlobalInvestmentFilter containerStyle={{ marginBottom: '-2px' }} />
            </>
        );
    };

    const renderAdvisorLogoAndNotificationsBell = () => {
        const renderAdvisorGroupLogo = () => {
            const logo = UserPermissions()?.advisor_logo_thumb;
            const showLogo = UserPermissions()?.advisor_logo_in_toolbar;
            if (!logo || !showLogo) return null;
            return <img style={{ height: '60px', marginRight: '15px' }} src={logo} alt="advisor group logo" />;
        };
        const renderArrows = () => {
            return (
                <>
                    <img src="/images/icons/advisor/advisorTitleLeftArrow.png" alt="Left Arrow" />
                    <img src="/images/icons/advisor/advisorTitleRightArrow.png" alt="Right Arrow" />
                </>
            );
        };
        return (
            <>
                {renderLogo && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0px',
                            right: 80,
                            position: 'absolute',
                            justifyContent: 'right',
                        }}
                    >
                        {renderAdvisorGroupLogo() || renderArrows()}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0px',
                                justifyContent: 'right',
                            }}
                        >
                            {/* {renderArrows()} */}
                            <img src="/images/icons/advisor/ADVISOR.png" alt="advisor" />
                            <img src="/images/icons/advisor/VUE.png" alt="VUE" style={{ marginRight: '0px' }} />
                        </div>
                    </div>
                )}
                <div style={{ position: 'absolute', right: 30 }}>
                    <NotificationBell
                        count={notifications?.filter((n) => !n.read).length ?? 0}
                        onClick={() => {
                            setViewNotifications();
                        }}
                        height={30}
                        aria-label="notifications"
                    />
                </div>
            </>
        );
    };

    const renderAdvisorRightHeader = () => {
        const isClientVue = location.pathname.includes('advisor/clients');

        return (
            <div>
                {!isClientVue && <div className="titleNotification">{renderAdvisorLogoAndNotificationsBell()}</div>}
                {isClientVue && (
                    <div
                        className="titleNotification"
                        style={{
                            gap: '10px',
                        }}
                    >
                        {renderAccountSelect()}
                        <GlobalInvestmentFilter containerStyle={{ marginBottom: '-2px' }} />
                    </div>
                )}
            </div>
        );
    };

    const renderAdminRightHeader = () => {
        const isClientVue = location.pathname.includes('admin/users');

        return (
            <div>
                {isClientVue && (
                    <div
                        className="titleNotification"
                        style={{
                            gap: '10px',
                        }}
                    >
                        {renderAccountSelect()}
                        <GlobalInvestmentFilter containerStyle={{ marginBottom: '-2px' }} />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="title noselect" data-testid="title-component" data-exclude-fields={vue === 'INVESTOR' ? 'ownership' : ''}>
            <div>{title}</div>

            <div className="title-selectors">
                {vue === VUES.INVESTOR && renderInvestorRightHeader()}
                {vue === VUES.ADVISOR && renderAdvisorRightHeader()}
                {vue === VUES.ADMIN && renderAdminRightHeader()}
            </div>
        </div>
    );
};

export default withRouter(Title);
