import { BulkUploadType } from '../types/bulkUploadTypes';
import { getAccountUploadHandler } from './AccountUploadHandler';
import BulkUploadHandler, { BulkUploadHandlerConfig } from './BulkUploadHandler';

import { getAdvisorUploadHandler } from './AdvisorUploadHandler';
import { getAdvisoryGroupUploadHandler } from './AdvisoryGroupUploadHandler';
import { getCommitmentUploadHandler } from './CommitmentUploadHandler';
import { getConnectionUploadHandler } from './ConnectionUploadHandler';
import { getContactUploadHandler } from './ContactUploadHandler';
import { getInvestmentMasterUploadHandler } from './InvestmentMasterUploadHandler';
import { getInvestmentUploadHandler } from './InvestmentUploadHandler';
import { getInvestorUploadHandler } from './InvestorUploadHandler';
import { getRalRequestUploadHandler } from './RalRequestUploadHandler';
import { getTransactionUploadHandler } from './TransactionUploadHandler';
import { getUserUploadHandler } from './UserUploadHandler';
import { getValuationUploadHandler } from './ValuationUploadHandler';
// const handlers = (): Record<BulkUploadType, (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>> => {
//     return {
//         [BulkUploadType.commitment]: getCommitmentUploadHandler,
//     };
// };
const bulkUploadHandlers: Record<BulkUploadType, (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>> = {
    [BulkUploadType.commitment]: getCommitmentUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.valuation]: getValuationUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.transaction]: getTransactionUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.account]: getAccountUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.investment]: getInvestmentUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.user]: getUserUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.investment_master]: getInvestmentMasterUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.contact]: getContactUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.advisory_group]: getAdvisoryGroupUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.connection]: getConnectionUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.investor]: getInvestorUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.advisor]: getAdvisorUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
    [BulkUploadType.ral_request]: getRalRequestUploadHandler as (config: BulkUploadHandlerConfig) => BulkUploadHandler<any, any>,
};

export default bulkUploadHandlers;
