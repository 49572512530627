import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api from '../../api';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { CreateInvestmentRequestCurrencyEnum, InvestmentTypes } from '../../openApiClient';
import { CURRENCIES } from '../../constants/currencyConstants';
import GenericDrawer from './GenericDrawer';
import '../../styles/addConnectionDrawer.css';

interface Connection {
    _id: string | null;
    name: string;
    displayName?: string;
}

interface Account {
    _id: string;
    name: string;
}

interface CommonData {
    investmentName?: string;
    account?: Account;
    connection?: Connection;
    investmentType?: string;
    currency: string;
}

interface State {
    connections: Connection[];
    accounts: Account[];
    investmentTypes: string[];
    commonData: CommonData;
    errors: Record<string, string>;
}

interface AddInvestmentDrawerProps extends RouteComponentProps {
    user: { _id: string };
    firm?: any;
    advisor?: any;
    loading: (timeout?: number, key?: string) => void;
    loaded: (key: string | undefined) => void;
    close: () => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData: () => void;
}

const AddInvestmentDrawer: React.FC<AddInvestmentDrawerProps> = ({ user, loading, loaded, close, setAlert, reloadData, firm, advisor }) => {
    const [state, setState] = useState<State>({
        connections: [],
        accounts: [],
        investmentTypes: [],
        commonData: {
            currency: CURRENCIES.USD,
        },
        errors: {},
    });

    const load = async () => {
        loading(320, 'AddInvestmentDrawer_load');

        try {
            const accounts = await api2.paginateApiRoute(async (paginate_params: any) => {
                return (
                    await api2.client.AccountApi.listAccounts({
                        users: user._id,
                        ...paginate_params,
                    })
                ).data.accounts;
            });

            const connections = (await api.get(`/connections?userId=${user._id}`))?.results ?? [];

            setState((prev) => ({
                ...prev,
                investmentTypes: Object.values(InvestmentTypes),
                accounts,
                connections,
            }));
        } catch (e) {
            console.error('Error loading data:', e);
        }

        loaded('AddInvestmentDrawer_load');
    };

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (firm || advisor) {
            load();
        }
    }, [firm, advisor]);

    const handleSubmit = async (formData: CommonData): Promise<boolean> => {
        if (!isSafeToSave()) {
            return false;
        }

        try {
            loading();

            const requestData = {
                CreateInvestmentRequest: {
                    name: formData.investmentName!,
                    type: formData.investmentType!,
                    account: formData.account!._id,
                    currency: formData.currency as CreateInvestmentRequestCurrencyEnum,
                    ...(formData.connection?._id && { connection: formData.connection._id }),
                },
            };

            await api2.client.InvestmentApi.createInvestment(requestData);
            setDismissableAlert(setAlert, 'Investment has been created.');
            await reloadData();

            return true;
        } catch (error: any) {
            setDismissableAlert(setAlert, 'Error creating investment.', true);
            setState((prev) => ({
                ...prev,
                errors: { ...prev.errors, submit: 'Failed to create investment' },
            }));
            return false;
        } finally {
            loaded(undefined);
        }
    };

    const checkCommonData = (data: CommonData, errors: Record<string, string>) => {
        if (!data.investmentName?.trim()) errors['investmentName'] = 'This field is required.';
        if (!data.account) errors['account'] = 'This field is required.';
        if (!data.investmentType) errors['investmentType'] = 'This field is required.';
        // if (!data.currency) errors['currency'] = 'This field is required.';
    };

    const isSafeToSave = (setErrors = true) => {
        const errors: Record<string, string> = {};
        checkCommonData(state.commonData, errors);
        const isSafe = Object.keys(errors).length === 0;

        if (setErrors) {
            if (!isSafe) {
                errors.submit = 'There are missing required fields.';
            }
            setState((prev) => ({ ...prev, errors: isSafe ? {} : errors }));
        }

        return isSafe;
    };

    const permissions = UserPermissions();

    const getFields = () => ({
        'Investment Name': {
            label: 'Investment Name',
            placeholder: 'Name',
            fieldName: 'investmentName',
            type: 'text',
            className: 'addNewAdvisorToFirm-input',
        },
        Account: {
            placeholder: 'Select Account',
            label: 'Entity',
            fieldName: 'account',
            type: 'option',
            options: state.accounts,
            optionField: 'name',
            optionClearable: false,
            className: 'addNewAdvisorToFirm-select',
        },
        Connection: {
            placeholder: 'Select Connection',
            label: 'Connection',
            fieldName: 'connection',
            type: 'option',
            options: state.connections.map((conn) => ({
                ...conn,
                displayName: state.connections.filter((c) => c.name === conn.name).length !== 1 ? `${conn.name} (${conn._id})` : conn.name,
            })),
            optionField: 'displayName',
            optionClearable: false,
            className: 'addNewAdvisorToFirm-select',
        },
        'Investment Type': {
            placeholder: 'Select Investment Type',
            label: 'Investment Type',
            fieldName: 'investmentType',
            type: 'option',
            options: state.investmentTypes,
            optionClearable: false,
            className: 'addNewAdvisorToFirm-select',
        },
        Currency: {
            placeholder: 'Select Currency',
            label: 'Currency',
            fieldName: 'currency',
            type: 'option',
            options: Object.values(CURRENCIES),
            optionClearable: false,
            className: 'addNewAdvisorToFirm-select',
        },
    });

    const handleStateUpdate = (fieldName: string, value: any) => {
        setState((prev) => ({
            ...prev,
            commonData: {
                ...prev.commonData,
                [fieldName]: value,
            },
        }));
    };

    if (!permissions.canCreateAnyInvestments) {
        return (
            <div className="addNewAdvisorToFirm-cancel" onClick={close}>
                <span className="a">Cancel</span>
            </div>
        );
    }

    return (
        <>
            <GenericDrawer
                title="Create Investment"
                close={close}
                onSubmit={handleSubmit}
                actionStr="Create Investment"
                loadingKey="AddInvestmentDrawer"
                loading={loading}
                loaded={loaded}
                errors={state.errors}
                setErrors={(newErrors) =>
                    setState((prev) => ({
                        ...prev,
                        errors: {
                            ...prev.errors,
                            ...newErrors,
                        },
                    }))
                }
                isSafeToSave={isSafeToSave}
                getFields={() => getFields()}
                loadData={load}
                className="addNewAdvisorToFirm-drawer"
                onFieldChange={handleStateUpdate}
                formData={state.commonData}
            />
            <div style={{ clear: 'both', height: '300px' }} />
        </>
    );
};

export default withRouter(AddInvestmentDrawer);
