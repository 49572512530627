import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SOCIAL_MEDIA_TYPES } from '../../constants/documentConstants';
import '../../styles/socialIcon.css';

interface SocialIconProps {
    type: keyof typeof SOCIAL_MEDIA_TYPES | string;
    link: string;
    size?: 'xs' | 'sm' | 'lg' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
}

export const SocialIcon: React.FC<SocialIconProps> = ({ type, link, size }) => {
    const social = SOCIAL_MEDIA_TYPES[type as keyof typeof SOCIAL_MEDIA_TYPES];

    if (!social || !link) return null;

    if (!link.startsWith('http')) {
        link = `https://${link}`;
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" title={link}>
            <FontAwesomeIcon key={`social_${type}`} icon={social.icon} size={size} className={`social_${type}`} />
        </a>
    );
};

export default SocialIcon;
