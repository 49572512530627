import React from 'react';

interface SortArrowProps {
    up?: boolean;
    down?: boolean;
}

export const SortArrow: React.FC<SortArrowProps> = ({ up, down }) => {
    const none = !up && !down;
    const showUp = none || up;
    const showDown = none || down;

    return (
        <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
            <div style={{ marginBottom: '-0px' }}>{showUp ? '▲' : '\u00A0'}</div>
            <div>{showDown ? '▼' : '\u00A0'}</div>
        </div>
    );
};
