import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// components
import { KeyValuePairList, Entry } from '../DrawerComponents/KeyValuePairList';
import renderInputError, { ErrorRecord } from '../renderInputError';

// utilities
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';

// constants
import { VUES } from '../../constants/constantStrings';

import '../../styles/addEditCommitmentDrawer.css';
import { openBulkUploaderManager } from '../Uploaders/BulkUploader/BulkUploaderManager/BulkUploaderManager';
import { BulkUploadType } from '../Uploaders/BulkUploader/types/bulkUploadTypes';

interface Commitment {
    _id?: string;
    date: string;
    amount: string;
    [key: string]: any;
}

interface Investment {
    _id: string;
    currency: string;
}

interface Props extends RouteComponentProps {
    investment: Investment;
    closeDrawer: () => void;
    loading: (duration?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData: () => Promise<void>;
    allUserInvestments?: Array<{ user: { _id: string } }>;
    modal?: any;
    vue?: string;
}

const sanitizeNumberStr = (str: string) => str.replace(/[^0-9.]/g, '');

const AddEditCommitmentDrawer: React.FC<Props> = ({ investment, closeDrawer, loading, loaded, setAlert, reloadData, allUserInvestments, modal, vue }) => {
    const [data, setData] = useState<Commitment[]>([]);
    const [entries, setEntries] = useState<Commitment[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const loadData = useCallback(
        async (investment: Investment) => {
            if (!investment) return;
            loading(320, 'addEditCommitmentDrawer');
            let data: Commitment[] = [];
            try {
                data = await api2.paginateApiRoute(async (paginate_params: any) => {
                    return (
                        await api2.client.CommitmentApi.listCommitments({
                            investments: [investment._id],
                            ...paginate_params,
                        })
                    ).data.commitments;
                });
            } catch (err) {
                console.error('Error getting commitments', err);
            }

            setData(data);
            loaded('addEditCommitmentDrawer');
        },
        [loading, loaded]
    );

    useEffect(() => {
        loadData(investment);
    }, [investment, loadData]);

    const isSafeToSave = (shouldSetErrors = true) => {
        const newErrors: Record<string, string> = {};
        entries.forEach((entry) => {
            if (typeof entry.amount === 'string') {
                entry.amount = sanitizeNumberStr(entry.amount);
            }
            if (!entry.date) {
                newErrors.submit = 'Date is missing or invalid.';
            }
            if (!Number.isFinite(Number(entry.amount))) {
                newErrors.submit = 'All amount values must be a valid number.';
            }
        });

        const isSafe = Object.keys(newErrors).length === 0;
        if (shouldSetErrors) {
            setErrors(
                isSafe
                    ? {}
                    : Object.entries(newErrors).reduce(
                          (acc, [key, value]) => ({
                              ...acc,
                              [key]: [value],
                          }),
                          {}
                      )
            );
        }
        return isSafe;
    };

    const makeDataCorrections = (entries: Commitment[]) => {
        return entries.map((entry) => ({
            ...entry,
            date: dateToUTCMidnight(entry.date),
            amount: Number(entry.amount),
        }));
    };

    const removeEntry = async (id: string) => {
        try {
            await api2.client.CommitmentApi.deleteCommitment({
                commitment_id: id,
            });
            setData(data.filter((d) => d._id !== id));
        } catch (err) {
            console.error('Error deleting commitment', err);
        }
    };

    const addOrUpdateCommitments = async () => {
        if (!isSafeToSave()) {
            return;
        }
        loading(320, 'addOrUpdatecommitments');

        const correctedEntries = makeDataCorrections(entries);
        const reqBody: Record<string, any> = {};
        if (vue === 'advisor') {
            reqBody.user = getUserId();
        }

        const toCreate = correctedEntries.filter((entry) => !entry._id);
        const toUpdate = correctedEntries.filter((entry) => entry._id);

        const apiCalls: Promise<any>[] = [];

        // Update existing commitments
        toUpdate.forEach((update_val) => {
            const fn = new Promise(async (resolve) => {
                const this_date = update_val.date instanceof Date ? update_val.date.toISOString().split('T')[0] : String(update_val.date);
                const this_amount = update_val.amount;

                try {
                    if (!update_val._id) {
                        throw new Error('Missing commitment ID for update');
                    }

                    // First update the commitment
                    await api2.client.CommitmentApi.updateCommitment({
                        commitment_id: update_val._id as string,
                        UpdateCommitmentRequest: {
                            ...reqBody,
                            date: this_date as string,
                            amount: this_amount,
                        },
                    });

                    // Then fetch the updated commitment using the original ID
                    const newCommitment = (
                        await api2.client.CommitmentApi.getCommitment({
                            commitment_id: update_val._id,
                        })
                    ).data.commitment;
                    resolve(newCommitment);
                } catch (err) {
                    console.error('Error updating commitment:', err);
                    console.error('Error details:', {
                        update_val,
                        reqBody,
                        this_date,
                        this_amount,
                    });
                    resolve(null);
                }
            });
            apiCalls.push(fn);
        });

        // Create new commitments
        reqBody.investment = investment._id;

        toCreate.forEach((create_val) => {
            const fn = new Promise(async (resolve) => {
                const this_date = create_val.date instanceof Date ? create_val.date.toISOString().split('T')[0] : String(create_val.date);
                const this_amount = create_val.amount;

                try {
                    const createRes = await api2.client.CommitmentApi.createCommitment({
                        CreateCommitmentRequest: {
                            ...reqBody,
                            date: this_date as string,
                            amount: this_amount,
                            investment: reqBody.investment,
                        },
                    });

                    // For create, we expect a commitment_id in the response
                    const commitmentId = (createRes.data as any).commitment_id;
                    if (!commitmentId) {
                        throw new Error('No commitment ID in create response');
                    }

                    const newCommitment = (
                        await api2.client.CommitmentApi.getCommitment({
                            commitment_id: commitmentId,
                        })
                    ).data.commitment;
                    resolve(newCommitment);
                } catch (err) {
                    console.error('Error creating commitment:', err);
                    console.error('Error details:', {
                        create_val,
                        reqBody,
                        this_date,
                        this_amount,
                    });
                    resolve(null);
                }
            });
            apiCalls.push(fn);
        });

        Promise.all(apiCalls).then(async (values) => {
            const failures = values.filter((v) => !v);

            if (failures.length > 0) {
                setDismissableAlert(setAlert, 'There was an error saving the commitments.', true);
            } else {
                setDismissableAlert(setAlert, 'Commitments have been updated.');
                await reloadData();
                setData(values as Commitment[]);
                closeDrawer();
            }
            loaded('addOrUpdatecommitments');
        });
    };

    const renderBulkUploadBtn = () => {
        const allowBulkUpload = vue !== VUES.INVESTOR;
        if (!allowBulkUpload) return null;
        return (
            <div
                className="ddt-tabElem ddt-addBtn"
                onClick={() =>
                    openBulkUploaderManager(
                        modal,
                        BulkUploadType.commitment,
                        {
                            investment: investment._id,
                        },
                        true
                    )
                }
            >
                <div className="plus-icon" />
                Bulk Upload
            </div>
        );
    };

    const getUserId = () => allUserInvestments?.[0]?.user?._id ?? allUserInvestments?.[0]?.user;

    return (
        <>
            <div className="addEditCommitted-drawer">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative',
                        marginBottom: '20px',
                    }}
                >
                    <h1 style={{ marginBottom: '0px' }}>Edit Commitment</h1>
                    {renderBulkUploadBtn()}
                </div>
                <div className="addEditCommitted-drawer-keyValueList">
                    <KeyValuePairList
                        errors={errors}
                        keyList={[
                            {
                                title: 'Transaction Date',
                                placeholder: 'Date',
                                field: 'date',
                                type: 'date',
                            },
                            {
                                title: 'Commitment Amount',
                                placeholder: 'Commitment',
                                field: 'amount',
                                type: 'text',
                            },
                            {
                                type: 'delete',
                                field: 'delete',
                                title: '',
                            },
                        ]}
                        data={data.sort((a, b) => (a.date === b.date ? 0 : a.date < b.date ? -1 : 1))}
                        removeEntry={removeEntry}
                        handleChange={(data: Entry[]) => {
                            const updatedEntries = data.map((entry) => ({
                                ...entry,
                                date: entry.date || '',
                                amount: entry.amount || '',
                            }));
                            setEntries(updatedEntries as Commitment[]);
                        }}
                    />
                </div>
                <div>
                    <button className="drawer-submit-button" onClick={addOrUpdateCommitments}>
                        Save
                    </button>
                    <div className="addEditCommitted-cancel">
                        <span className="a" onClick={closeDrawer}>
                            Cancel
                        </span>
                    </div>
                    {renderInputError(errors as unknown as ErrorRecord, 'submit')}
                    <div style={{ clear: 'both', height: '20px' }} />
                </div>
            </div>
            <div style={{ clear: 'both', height: '300px' }} />
        </>
    );
};

export default withRouter(AddEditCommitmentDrawer);
