// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { faCircleXmark, faEye } from '@fortawesome/free-solid-svg-icons';
import api2 from '../../api2';
import '../../styles/widget-AdvisoryGroupSettingsControls.css';
import '../../styles/widget.css';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { capitalizeFirstEveryWord } from '../../utilities/format/capitalizeFirst';
import IconButton from '../Buttons/IconButton';
import Multitoggle from '../Toggles/Multitoggle';

const LOGO_THUMB_SIZE = 100;

interface AdvisoryGroupSettingsControlsProps extends RouteComponentProps {
    user: any;
    group_id: string;
    setAlert: (message: string, isError: boolean) => void;
    reloadAdvisoryGroup: () => void;
    history: any;
    loading: (left: number | string, key: string) => void;
    loaded: (key: string) => void;
}

const AdvisoryGroupSettingsControls: React.FC<AdvisoryGroupSettingsControlsProps> = (props) => {
    const [group, setGroup] = useState<any>(null);
    const [showSettingsTooltip, setShowSettingsTooltip] = useState<boolean>(false);

    // fetch Advisory Group
    const loadData = useCallback(async () => {
        if (!props.user || !props.group_id) return;
        props.loading(0, 'advisoryGroupSettingsControls_loadData');
        try {
            const advisoryGroup = (
                await api2.client.AdvisoryGroupApi.getAdvisoryGroupById({
                    advisory_group_id: props.group_id,
                    populate_logo_urls: true,
                })
            ).data.advisory_group;
            setGroup(advisoryGroup);
        } catch (error) {
            setDismissableAlert(props.setAlert, 'Error loading advisory group settings', true);
        }
        props.loaded('advisoryGroupSettingsControls_loadData');
    }, [props.user, props.group_id]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    // reload global group and user permissions
    const reloadGlobalGroup = async () => {
        await props.reloadAdvisoryGroup();
        UserPermissions(true);
    };

    // update advisory group settings in the database
    const updateAdvisoryGroupSettings = async (settingsDiff: any) => {
        setGroup((prevGroup: any) => ({ ...prevGroup, settings: { ...group.settings, ...settingsDiff } }));
        props.loading(0, 'advisoryGroupSettingsControls_updateAdvisoryGroupSettings');

        const allowedSettings = ['hide_fees_for_investors', 'hide_fees_for_advisors', 'logo_in_toolbar'];

        // remove settings that are not allowed
        const newSettings = { ...group.settings, ...settingsDiff };
        Object.keys(newSettings).forEach((key) => {
            if (!allowedSettings.includes(key)) {
                delete newSettings[key];
            }
        });

        try {
            await api2.client.AdvisoryGroupApi.updateAdvisoryGroup({
                advisorygroup_id: props.group_id,
                UpdateAdvisoryGroupRequest: {
                    settings: newSettings,
                },
            });
        } catch (error) {
            setDismissableAlert(props.setAlert, 'Error updating advisory group settings', true);
        }
        props.loaded('advisoryGroupSettingsControls_updateAdvisoryGroupSettings');
    };

    // toggle settings field
    const handleAdvisoryGroupSettingsToggle = async (key: string, enabled: boolean) => {
        if (!props.group_id) return;
        const newEnabled = !enabled;
        await updateAdvisoryGroupSettings({ [key]: newEnabled });
    };

    const renderAdvisoryGroupSettingsToggle = (key: string, enabled: boolean) => {
        return (
            <div style={{ display: 'flex', height: '40px', alignItems: 'center' }} key={`setting_${key}`}>
                <div style={{ width: '200px', fontSize: '18px' }}>{capitalizeFirstEveryWord(key.replace(/_/g, ' '))}</div>
                <Multitoggle
                    keyStr={`settingToggle${key}`}
                    options={['OFF', 'ON']}
                    selection={enabled ? 'ON' : 'OFF'}
                    zIndex={2000}
                    onSelect={() => handleAdvisoryGroupSettingsToggle(key, enabled)}
                    containerStyle={{
                        width: '130px',
                        height: '24px',
                    }}
                />
            </div>
        );
    };

    // add or remove logo.  event is null if deleting logo
    const setGroupLogo = async (event: React.ChangeEvent<HTMLInputElement> | null) => {
        if (!props.group_id) return;
        const delete_logo = event === null;

        // add file to form data if not deleting logo
        const formData = new FormData();
        if (!delete_logo) {
            const file = event?.target.files?.[0];
            if (!file) return;
            formData.append('file', file);
        }

        props.loading(0, 'advisoryGroupSettingsControls_setGroupLogo');

        try {
            const logoResult = await api2.post(`/advisorygroups/${props.group_id}/logo${delete_logo ? '?delete_logo=true' : ''}`, formData);
            if (logoResult.success) {
                setDismissableAlert(props.setAlert, `Logo ${delete_logo ? 'removed' : 'uploaded'} successfully`, false);
                loadData();
                reloadGlobalGroup();
            } else {
                setDismissableAlert(props.setAlert, `Error ${delete_logo ? 'removing' : 'uploading'} logo`, true);
            }
        } catch (error) {
            console.error(error);
            setDismissableAlert(props.setAlert, `Error ${delete_logo ? 'removing' : 'uploading'} logo`, true);
        }

        props.loaded('advisoryGroupSettingsControls_setGroupLogo');
    };

    const groupSettings = group?.settings;
    if (!groupSettings) return null;
    const logo_thumb_url = groupSettings.logo_thumb_url;

    return (
        <div className="investment-accounts" style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1 style={{ margin: '0px' }}>Advisory Group Settings</h1>
                <div style={{ width: '20px', height: '20px' }}>
                    <div
                        className="show-tooltip"
                        style={{ marginLeft: '20px' }}
                        onClick={() => setShowSettingsTooltip(!showSettingsTooltip)}
                        onMouseEnter={() => setShowSettingsTooltip(true)}
                        onMouseLeave={() => setShowSettingsTooltip(false)}
                    >
                        ?
                    </div>
                    {showSettingsTooltip && <div className="popper">Adjust the settings below to customize your advisory group preferences.</div>}
                </div>
            </div>
            <div
                className="settings-list"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                {/* hide fees for investors and advisors */}
                {['hide_fees_for_investors', 'hide_fees_for_advisors'].map((key, keyIdx) => {
                    const enabled = groupSettings[key];
                    return renderAdvisoryGroupSettingsToggle(key, enabled);
                })}

                <div style={{ marginTop: '10px' }} />

                {/* add/remove logo */}
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', ...(logo_thumb_url ? { marginBottom: '10px' } : {}) }}>
                    <div style={{ width: '200px', fontSize: '18px' }}>Logo</div>
                    {logo_thumb_url && (
                        <>
                            <img src={logo_thumb_url} alt="Logo" style={{ height: `${LOGO_THUMB_SIZE}px`, marginRight: '20px' }} />
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: `${LOGO_THUMB_SIZE}px`, alignItems: 'center' }}>
                                <IconButton
                                    faIcon={faCircleXmark}
                                    color="var(--color-dark-red)"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to remove the logo?')) {
                                            setGroupLogo(null);
                                        }
                                    }}
                                    title="Remove Logo"
                                />
                                <IconButton
                                    faIcon={faEye}
                                    // color="var(--color-dark-red)"
                                    onClick={() => {
                                        window.open(groupSettings.logo_full_url || logo_thumb_url, '_blank');
                                    }}
                                    title="View Logo"
                                />
                            </div>
                        </>
                    )}
                    {!logo_thumb_url && (
                        <span>
                            <input type="file" accept="image/*" onChange={(event) => setGroupLogo(event)} />
                        </span>
                    )}
                </div>
                {/* logo in toolbar, if logo is set */}
                {logo_thumb_url && (
                    <>
                        <div style={{ display: 'flex', height: '40px', alignItems: 'center' }}>
                            {renderAdvisoryGroupSettingsToggle('logo_in_toolbar', groupSettings.logo_in_toolbar)}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRouter(AdvisoryGroupSettingsControls);
