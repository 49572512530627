import React from 'react';

import '../../styles/visibilityIcon.css';

interface VisibilityIconProps {
    show?: boolean;
    toggleShow: () => void;
}

export const VisibilityIcon: React.FC<VisibilityIconProps> = ({ show, toggleShow }) => {
    return (
        <img
            className="visibility-icon-image"
            style={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
            src={`/images/icons/eye${show ? '_filled' : ''}.png`}
            alt={`${show ? 'Hide' : 'Show'} Password`}
            title={`${show ? 'Hide' : 'Show'} Password`}
            onClick={toggleShow}
        />
    );
};
