import React, { useState, useEffect } from 'react';
import renderInputError from '../renderInputError';
import api from '../../api';
import '../../styles/clientSettingsDrawer.css';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import formatDate from '../../utilities/format/formatDate';
import DeactivateDrawer from '../Drawers/DeactivateDrawer';
import AddEditAccountDrawer from '../Drawers/AddEditAccountDrawer';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import resendEmailVerificationEmail from '../../utilities/apiHelpers/resendEmailVerificationEmail';
import api2 from '../../api2';
import rephraseClientStatus from '../../utilities/AdvisorVue/rephraseClientStatus';

interface ClientSettingsDrawerProps {
    client: any;
    loading: (duration?: number, key?: string) => void;
    loaded: (key?: string) => void;
    loadClients: () => Promise<void>;
    setAlert: (message: string | JSX.Element, isError?: boolean, duration?: number) => void;
    closeDrawer: () => void;
    openDrawer: (content: () => JSX.Element) => void;
    close: () => void;
}

const ClientSettingsDrawer: React.FC<ClientSettingsDrawerProps> = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [confirmReset, setConfirmReset] = useState(false);
    const [confirmDeactivate, setConfirmDeactivate] = useState(false);
    const [tmpSetupEmailSendTime, setTmpSetupEmailSendTime] = useState<Date | null>(null);
    const [accounts, setAccounts] = useState<any[]>([]);

    useEffect(() => {
        load();
    }, [props.client]);

    const load = async () => {
        if (!props.client) return;

        setName(props.client.name);
        setEmail(props.client.email);
        setAccounts(props.client.accounts ?? []);
    };

    const saveChanges = async () => {
        let errors: any = {};

        if (!name) errors.name = 'This field is required.';
        if (!email) errors.email = 'This field is required.';

        if (Object.keys(errors).length === 0) {
            props.loading(320, 'saveChanges');
            try {
                const res = await api.patch(`/users/${props.client?._id}`, {
                    name,
                    email,
                });
                if (res) {
                    setTimeout(() => props.loaded('saveChanges'), 1000);
                    await props.loadClients();
                    setDismissableAlert(props.setAlert, 'Client settings updated.');
                    props.closeDrawer();
                    return;
                } else {
                    errors.save = 'An error occurred while saving the client.';
                }
            } catch (error) {
                errors.save = 'An error occurred while saving the client.';
            } finally {
                props.loaded('saveChanges');
            }
        }

        setErrors(errors);
    };

    const resetPassword = async (e: React.MouseEvent, isFirstSetup = false) => {
        e.stopPropagation();
        if (confirmReset) {
            props.loading(320, 'resetPw');
            const res = await api.post(
                isFirstSetup ? '/users/sendsetupemail' : '/users/forgot/',
                { email: props.client?.email },
                {
                    500: (err: Error) => console.error(err),
                }
            );
            props.loaded('resetPw');
            if (res) {
                if (isFirstSetup) {
                    setTmpSetupEmailSendTime(new Date());
                }
                setDismissableAlert(
                    props.setAlert,
                    <>
                        A {isFirstSetup ? 'setup' : 'password reset'} email has been sent to <strong style={{ color: '#fff' }}>{props.client?.email}</strong>.
                    </>,
                    false,
                    5000
                );
            }
            setConfirmReset(false);
        } else {
            setConfirmReset(true);
            setTimeout(() => {
                if (confirmReset) {
                    setConfirmReset(false);
                }
            }, 1500);
        }
    };

    const deactivateClient = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (confirmDeactivate) {
            props.loading(320, 'deactivateClient');
            const res = await api.patch(`/users/${props.client._id}/group`, {
                group: props.client.currentAdvisor.group,
                action: 'deactivate',
            });
            props.loaded('deactivateClient');
            if (res) {
                await props.loadClients();
                setDismissableAlert(props.setAlert, 'The client has been deactivated.');
                props.closeDrawer();
                return;
            }
            setConfirmDeactivate(false);
        } else {
            setConfirmDeactivate(true);
            setTimeout(() => {
                if (confirmDeactivate) {
                    setConfirmDeactivate(false);
                }
            }, 1500);
        }
    };

    const renderAddEditAccountDrawer = (account: any) => {
        props.openDrawer(() => (
            <AddEditAccountDrawer
                accountId={account?._id}
                close={props.closeDrawer}
                setAlert={(message: string | JSX.Element, isError?: boolean) => {
                    if (typeof message === 'string' || message instanceof Element) {
                        props.setAlert(message, isError);
                    } else {
                        props.setAlert(String(message), isError);
                    }
                }}
                loaded={props.loaded}
                loading={props.loading}
                reloadData={props.loadClients}
            />
        ));
    };

    const renderDeactivateDrawer = (account: any) => {
        props.openDrawer(() => (
            <DeactivateDrawer
                dataType="entity"
                name={account.name}
                deactivate={async () => {
                    try {
                        await api2.client.AccountApi.deleteAccount({
                            account_id: account._id,
                        });
                        return true;
                    } catch (e) {
                        console.log('Error deactivating account:', e);
                        return false;
                    }
                }}
                close={props.closeDrawer}
                reloadData={props.loadClients}
                loaded={props.loaded}
                loading={props.loading}
                setAlert={props.setAlert}
            />
        ));
    };

    const clientIsActive = props.client.status === 'Active';
    const clientEmailVerified = props.client.email_verification?.verified;
    const isConnReq = props.client.isConnectionRequest;
    const lastSetupEmailSendTime =
        tmpSetupEmailSendTime ?? (props.client.setup_email_send_times?.length > 0 ? props.client.setup_email_send_times?.[props.client.setup_email_send_times?.length - 1] : null);
    const lastEmailVerificationSendTime = props.client.email_verification.send_times?.[props.client.email_verification.send_times.length - 1];

    return (
        <div className="clientSettings-drawer">
            <h1>Client Settings</h1>
            <div>
                <label htmlFor="name">Full Name</label>
                <input
                    readOnly={!clientIsActive || !UserPermissions().canUpdateClients}
                    id="name"
                    placeholder="Full Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                {renderInputError(errors, 'name')}

                <label htmlFor="name">Email Address</label>
                <input
                    readOnly={!clientIsActive || !UserPermissions().canUpdateClients}
                    id="name"
                    placeholder="Email Address"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {renderInputError(errors, 'email')}

                {UserPermissions().canUpdateClients && clientIsActive && (
                    <button className="drawer-submit-button flex-center" onClick={saveChanges} style={{ marginTop: '20px', marginLeft: '0px' }}>
                        Save Changes
                    </button>
                )}

                {!clientEmailVerified && (
                    <>
                        <h3 style={{ marginBottom: '20px' }}>Email Not Verified</h3>
                        <div className="clientSettings-info" style={{ alignItems: 'center' }}>
                            <span />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                <span
                                    className="a"
                                    onClick={(e) => resendEmailVerificationEmail(e, props.client.email, props.setAlert, props.loadClients)}
                                >{`${lastEmailVerificationSendTime ? 'Resend' : 'Send'} Verification Email`}</span>
                                Last sent: {lastEmailVerificationSendTime ? formatDate(lastEmailVerificationSendTime, false, false, true) : 'Never'}
                            </div>
                        </div>
                    </>
                )}

                {clientIsActive && (
                    <>
                        <h3 style={{ marginBottom: '20px' }}>Password</h3>

                        <div className="clientSettings-info" style={{ alignItems: 'center' }}>
                            <span style={{ fontSize: '18px' }}>{props.client.hasPassword ? '*************' : 'Not set'}</span>
                            {UserPermissions().canUpdateClients && (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span className="a" onClick={(e) => resetPassword(e, !props.client.hasPassword)}>
                                        {confirmReset ? 'Confirm' : props.client.hasPassword ? 'Reset Password' : `${lastSetupEmailSendTime ? 'Resend' : 'Send'} Setup Email`}
                                    </span>
                                    {!props.client.hasPassword && (
                                        <span>Last sent: {lastSetupEmailSendTime ? formatDate(lastSetupEmailSendTime, false, false, true) : 'Never'}</span>
                                    )}
                                </div>
                            )}
                        </div>
                        {renderInputError(errors, 'reset')}
                    </>
                )}

                <h3>Status</h3>

                <div className="clientSettings-info">
                    <span>{isConnReq ? 'Connection Request' : rephraseClientStatus(props.client.status)}</span>
                    {UserPermissions().canDeleteAccounts && clientIsActive && (
                        <span className="clientSettings-info-deactivate" onClick={deactivateClient} style={{ color: 'var(--color-dark-red)' }}>
                            {confirmDeactivate ? 'Confirm' : 'Deactivate Client'}
                        </span>
                    )}
                </div>
                {renderInputError(errors, 'deactivate')}

                {UserPermissions().canReadAccounts && !isConnReq && (
                    <>
                        <h3>Entities</h3>

                        {accounts?.map((acc) => {
                            // const lastRalSend = acc.ral_send_times?.length ? formatDate(acc.ral_send_times[acc.ral_send_times.length - 1]) : null;
                            return (
                                <div className="clientSettings-info clientSettings-entity" key={`acc${acc._id}`}>
                                    <div className="clientSettings-entity-name">{acc.name}</div>
                                    <div className="clientSettings-entity-buttons">
                                        {UserPermissions().canDeleteAccounts && (
                                            <div className="clientSettings-info-deactivate" onClick={() => renderDeactivateDrawer(acc)}>
                                                Deactivate
                                            </div>
                                        )}

                                        {UserPermissions().canUpdateAccounts && (
                                            <div className="a" onClick={() => renderAddEditAccountDrawer(acc)}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>

            <div className="clientSettings-cancel" onClick={props.close}>
                <span className="a">Close</span>
            </div>
            {renderInputError(errors, 'save')}
        </div>
    );
};

export { ClientSettingsDrawer };
