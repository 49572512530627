import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from '../../styles/iconTooltip.module.scss';

const TOOLTIP_PADDING = 10;

interface IconTooltipProps {
    text: string | JSX.Element;
    icon?: IconDefinition;
    containerStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    popupStyle?: React.CSSProperties;
}

const IconTooltip: React.FC<IconTooltipProps> = ({ text, icon = faCircleInfo, containerStyle, iconStyle, popupStyle }) => {
    const [isHovered, setIsHovered] = useState(false);
    const iconRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handlePosition = () => {
            if (tooltipRef.current && iconRef.current) {
                const tooltipRect = tooltipRef.current.getBoundingClientRect();
                const iconRect = iconRef.current.getBoundingClientRect();

                const newLeft = iconRect.left + 40 + TOOLTIP_PADDING;
                const newTop = iconRect.top + TOOLTIP_PADDING;
                const newRight = newLeft + tooltipRect.width;
                const newBottom = newTop + tooltipRect.height;

                tooltipRef.current.style.left = `${newLeft}px`;
                tooltipRef.current.style.top = `${newTop}px`;

                // tooltipRef.current.style.right = `${newRight}px`;
                // tooltipRef.current.style.bottom = `${newBottom}px`;

                const gap = 0;

                let rightExpected = window.innerWidth - gap;
                let rightOverflow = newRight - rightExpected;
                if (rightOverflow > 0) {
                    tooltipRef.current.style.marginLeft = `-${rightOverflow}px`;
                }

                let leftExpected = gap;
                let leftOverflow = leftExpected - newLeft;
                if (leftOverflow > 0) {
                    tooltipRef.current.style.marginLeft = `${leftOverflow}px`;
                }

                let topExpected = gap * 3;
                let topOverflow = topExpected - newTop;
                if (topOverflow > 0) {
                    tooltipRef.current.style.marginTop = `${topOverflow}px`;
                }

                let bottomExpected = window.innerHeight - gap;
                let bottomOverflow = newBottom - bottomExpected;
                if (bottomOverflow > 0) {
                    tooltipRef.current.style.marginTop = `-${bottomOverflow}px`;
                }
            }
        };

        if (isHovered) {
            handlePosition();
        }
    }, [isHovered]);

    return (
        <div className={styles.main} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={containerStyle}>
            <div ref={iconRef}>
                <FontAwesomeIcon
                    icon={icon}
                    style={{
                        width: 16,
                        height: 16,
                        color: 'var(--color-primary)',
                        cursor: 'pointer',
                        ...iconStyle,
                    }}
                />
            </div>
            {/* {isHovered && (
                <p
                    className={styles.text}
                    ref={tooltipRef}
                    style={{
                        ...popupStyle,
                    }}
                >
                    {text}
                </p>
            )} */}
            {isHovered &&
                ReactDOM.createPortal(
                    <p
                        className={styles.text}
                        ref={tooltipRef}
                        style={{
                            padding: TOOLTIP_PADDING,
                            ...popupStyle,
                            position: 'fixed',
                            // Change position to fixed to position relative to the viewport
                            // Calculate and set the position dynamically based on the triggering element's position
                        }}
                    >
                        {text}
                    </p>,
                    document.body // Renders the tooltip as a child of body
                )}
        </div>
    );
};

export default IconTooltip;
