// must set the thisUser variable before calling canAccessPage
// let thisUser = null
// export const setPermissionUser = (user) => {
//     thisUser = user
// }

import { VUES } from '../../constants/constantStrings';

let thisUser = null;
let thisGroup = null;
let thisUserGroup = null;
let thisUserPermissions = null;

export const AUTH_CATEGORIES = {
    CREATE: 'c',
    READ: 'r',
    UPDATE: 'u',
    DELETE: 'd',
    ALL: '*',
};

export const loadIfNotLoaded = (forceReload = false) => {
    if (forceReload) return loadUserAndGroup();
    // If there is a non advisor user loaded, then we are fine
    if (thisUser && !thisGroup && ['investor', 'admin'].includes(thisUser.access)) return;
    // If there is no user or group loaded, then load them
    if (!thisUser || !thisGroup) loadUserAndGroup();
};

const loadUserAndGroup = () => {
    thisUser = JSON.parse(sessionStorage.getItem('USER'));

    if (thisUser?.access === VUES.ADVISOR) {
        thisGroup = JSON.parse(sessionStorage.getItem('ADVISORY_GROUP'));
    } else if (thisUser?.access === VUES.INVESTOR) {
        thisUserGroup = thisUser.advisors?.[0]?.group;
    }
};

// just checks if frontendPages has the permission exactly
export const hasPermission = (permission, forceReload = false) => {
    loadIfNotLoaded(forceReload);
    if (!thisUser || !thisGroup) return false;

    let grants = thisGroup.plan.frontendPages;
    if (!grants || !grants.length) return false;

    return grants.includes(permission);
};

export const canAccessPage = (route, categories = [AUTH_CATEGORIES.ALL], print = false, forceReload = false) => {
    loadIfNotLoaded(forceReload);
    if (thisUser && ['investor', 'admin'].includes(thisUser.access)) return true;
    if (!thisUser || !thisGroup) return false;

    // get grants from group
    let grants = thisGroup.plan.frontendPages;

    // block if no user
    if (!thisUser) return false;
    // if not advisor, can access all pages
    if (['investor', 'admin'].includes(thisUser.access)) return true;
    // if advisor doesn't have a plan or frontendPages, can't access any pages
    if (!thisGroup?.plan?.frontendPages?.length) return false;
    // must have grants and route to continue
    if (!grants || !grants.length || !route) return false;

    // get negative grants (grants that start with !)
    const negGrants = grants.filter((grant) => grant.startsWith('!')).map((grant) => grant.slice(1));
    // remove negative grants from grants
    grants = grants.filter((grant) => !grant.startsWith('!'));

    // split route into namespace and component array
    const routeComponents = route.split('.');
    const routeNamespace = routeComponents.slice(0, -1).join('.');

    // build valid grants object
    const validGrants = {
        [AUTH_CATEGORIES.ALL]: true,
        [route]: true,
    };

    // make sure categories is at least an array with AUTH_CATEGORIES.ALL
    if (!categories) categories = [AUTH_CATEGORIES.ALL];
    if (!Array.isArray(categories)) categories = [categories];
    if (!categories.includes(AUTH_CATEGORIES.ALL)) categories.push(AUTH_CATEGORIES.ALL);

    // add grants to validGrants with all possible combinations
    categories.forEach((category) => {
        validGrants[category] = true;
        validGrants[`${routeNamespace}.${category}`] = true;
        validGrants[`${route}.${category}`] = true;
    });

    // subtract negGrants from grants
    let isBanned = false;
    negGrants.forEach((negGrant) => {
        const negGrantComponents = negGrant.split('.');
        const negGrantNamespace = negGrantComponents.slice(0, -1).join('.');
        const negGrantCategory = negGrantComponents[negGrantComponents.length - 1];

        if (print) console.log('negGrant', validGrants, negGrant, negGrantComponents, negGrantNamespace, '\n', negGrantCategory);

        if (negGrantCategory === AUTH_CATEGORIES.ALL) {
            // ban all namespace matches if category is *
            Object.keys(validGrants).forEach(() => {
                if (route.startsWith(negGrantNamespace)) {
                    isBanned = true;
                }
            });
        } else {
            // ban only the specific category if category is not *
            Object.keys(validGrants).forEach(() => {
                categories.forEach((category) => {
                    if (`${route}.${category}`.startsWith(negGrantNamespace)) {
                        isBanned = true;
                    }
                });
            });
        }
    });

    if (print) {
        console.log('user', thisUser);
        console.log('route', route);
        console.log('categories', categories);
        console.log('validGrants', validGrants);
        console.log('grants', grants);
        console.log('negGrants', negGrants);
        console.log('isBanned', isBanned);
    }

    return !isBanned && grants.some((grant) => validGrants[grant]);
};

export const UI_ROUTES = {
    // NOTIFICATIONS_DRAWER: 'notificationsDrawer',
    NOTIFICATIONS: 'notifications',

    SET_USER_STAGING_ACTIVE: 'superToolbar.setUserStagingActive',

    // investor pages
    // investments page
    INVESTMENTS: 'investments', // (legacy - portalInvestments and manualInvestments are now separate pages)
    PORTAL_INVESTMENTS: 'portalInvestments',
    MANUAL_INVESTMENTS: 'manualInvestments',

    // capitalCalls page
    CAPITAL_CALLS: 'capitalCalls',
    CAPITAL_CALLS__FUNDED: 'capitalCalls.funded',

    // transactions page
    TRANSACTIONS: 'transactions',

    // documents page
    DOCUMENTS: 'documents',

    // taxCenter page
    TAX_CENTER: 'taxCenter',

    // // manageInvestments page
    // MANAGE_INVESTMENTS: 'manageInvestments',

    // // manageConnections page
    // MANAGE_CONNECTIONS: 'manageConnections',

    CONNECTIONS: 'connections',

    // performance page
    PERFORMANCE: 'performance',

    ACCOUNTS: 'accounts',

    CLIENTS: 'clients',

    EXPORTS: 'exports',
};

export const PREMIUM_FEATURE_LOCKS = {
    SET_USER_STAGING_ACTIVE: 'premiumFeatureLock.setUserStagingActive',
};

// uses permissions singleton
export const UserPermissions = (forceReload = false) => {
    // return permissions if already loaded
    if (thisUserPermissions && !forceReload) return thisUserPermissions;
    // load user and group if not already loaded
    loadIfNotLoaded(forceReload);
    // if no user or group, return empty permissions
    if ((!thisUser || !thisGroup) && !['investor', 'admin'].includes(thisUser?.access))
        return {
            userAccess: thisUser?.access,
            access: {
                investor: thisUser?.access === 'investor',
                advisor: thisUser?.access === 'advisor',
                admin: thisUser?.access === 'admin',
            },
        };

    // build permissions object
    const permissions = {
        userAccess: thisUser?.access,

        // capital calls
        canReadCapitalCalls: canAccessPage(UI_ROUTES.CAPITAL_CALLS, AUTH_CATEGORIES.READ),
        canUpdateCapitalCalls: canAccessPage(UI_ROUTES.CAPITAL_CALLS, AUTH_CATEGORIES.UPDATE),

        // documents
        canReadDocuments: canAccessPage(UI_ROUTES.DOCUMENTS, AUTH_CATEGORIES.READ),
        canCreateDocuments: canAccessPage(UI_ROUTES.DOCUMENTS, AUTH_CATEGORIES.CREATE),
        canUpdateDocuments: canAccessPage(UI_ROUTES.DOCUMENTS, AUTH_CATEGORIES.UPDATE),
        canDeleteDocuments: canAccessPage(UI_ROUTES.DOCUMENTS, AUTH_CATEGORIES.DELETE),

        // portal investments
        canReadPortalInvestments: canAccessPage(UI_ROUTES.PORTAL_INVESTMENTS, AUTH_CATEGORIES.READ) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.READ),
        canCreatePortalInvestments: canAccessPage(UI_ROUTES.PORTAL_INVESTMENTS, AUTH_CATEGORIES.CREATE) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.CREATE),
        canUpdatePortalInvestments: canAccessPage(UI_ROUTES.PORTAL_INVESTMENTS, AUTH_CATEGORIES.UPDATE) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.UPDATE),
        canDeletePortalInvestments: canAccessPage(UI_ROUTES.PORTAL_INVESTMENTS, AUTH_CATEGORIES.DELETE) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.DELETE),

        // manual investments
        canReadManualInvestments: canAccessPage(UI_ROUTES.MANUAL_INVESTMENTS, AUTH_CATEGORIES.READ) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.READ),
        canCreateManualInvestments: canAccessPage(UI_ROUTES.MANUAL_INVESTMENTS, AUTH_CATEGORIES.CREATE) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.CREATE),
        canUpdateManualInvestments: canAccessPage(UI_ROUTES.MANUAL_INVESTMENTS, AUTH_CATEGORIES.UPDATE) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.UPDATE),
        canDeleteManualInvestments: canAccessPage(UI_ROUTES.MANUAL_INVESTMENTS, AUTH_CATEGORIES.DELETE) || canAccessPage(UI_ROUTES.INVESTMENTS, AUTH_CATEGORIES.DELETE),

        // notifications drawer
        canReadNotifications: canAccessPage(UI_ROUTES.NOTIFICATIONS, AUTH_CATEGORIES.READ),
        canUpdateNotifications: canAccessPage(UI_ROUTES.NOTIFICATIONS, AUTH_CATEGORIES.UPDATE),

        // exports
        canExport: canAccessPage(UI_ROUTES.EXPORTS),

        // clients
        canReadClients: canAccessPage(UI_ROUTES.CLIENTS, AUTH_CATEGORIES.READ),
        canUpdateClients: canAccessPage(UI_ROUTES.CLIENTS, AUTH_CATEGORIES.UPDATE),

        // accounts
        canReadAccounts: canAccessPage(UI_ROUTES.ACCOUNTS, AUTH_CATEGORIES.READ),
        canCreateAccounts: canAccessPage(UI_ROUTES.ACCOUNTS, AUTH_CATEGORIES.CREATE),
        canUpdateAccounts: canAccessPage(UI_ROUTES.ACCOUNTS, AUTH_CATEGORIES.UPDATE),
        canDeleteAccounts: canAccessPage(UI_ROUTES.ACCOUNTS, AUTH_CATEGORIES.DELETE),

        // connections
        canReadConnections: canAccessPage(UI_ROUTES.CONNECTIONS, AUTH_CATEGORIES.READ),
        canCreateConnections: canAccessPage(UI_ROUTES.CONNECTIONS, AUTH_CATEGORIES.CREATE),
        canUpdateConnections: canAccessPage(UI_ROUTES.CONNECTIONS, AUTH_CATEGORIES.UPDATE),
        canDeleteConnections: canAccessPage(UI_ROUTES.CONNECTIONS, AUTH_CATEGORIES.DELETE),

        // transactions
        canReadTransactions: canAccessPage(UI_ROUTES.TRANSACTIONS, AUTH_CATEGORIES.READ),
        canUpdateTransactions: canAccessPage(UI_ROUTES.TRANSACTIONS, AUTH_CATEGORIES.UPDATE),
        canCreateTransactions: canAccessPage(UI_ROUTES.TRANSACTIONS, AUTH_CATEGORIES.CREATE),

        // super toolbar
        canSetUserStagingActive: canAccessPage(UI_ROUTES.SET_USER_STAGING_ACTIVE, AUTH_CATEGORIES.UPDATE),

        // performance
        canReadPerformance: canAccessPage(UI_ROUTES.PERFORMANCE, AUTH_CATEGORIES.READ),

        // tax center
        canReadTaxCenter: canAccessPage(UI_ROUTES.TAX_CENTER, AUTH_CATEGORIES.READ),
        canUpdateTaxCenter: canAccessPage(UI_ROUTES.TAX_CENTER, AUTH_CATEGORIES.UPDATE),
    };

    // track if user can read/create/update/delete any investments
    permissions.canReadAnyInvestments = permissions.canReadPortalInvestments || permissions.canReadManualInvestments;
    permissions.canCreateAnyInvestments = permissions.canCreatePortalInvestments || permissions.canCreateManualInvestments;
    permissions.canUpdateAnyInvestments = permissions.canUpdatePortalInvestments || permissions.canUpdateManualInvestments;
    permissions.canDeleteAnyInvestments = permissions.canDeletePortalInvestments || permissions.canDeleteManualInvestments;

    // premium feature locks
    permissions.featureLocks = {
        setUserStagingActive: hasPermission(PREMIUM_FEATURE_LOCKS.SET_USER_STAGING_ACTIVE),
    };

    permissions.access = {
        investor: permissions.userAccess === VUES.INVESTOR,
        advisor: permissions.userAccess === VUES.ADVISOR,
        admin: permissions.userAccess === VUES.ADMIN,
    };

    // handle AdvisoryGroup settings
    permissions.hide_fees = false;

    const relevantGroup = thisGroup || thisUserGroup;
    if (relevantGroup) {
        if (permissions.userAccess === VUES.INVESTOR) {
            permissions.hide_fees = relevantGroup?.settings?.hide_fees_for_investors;
        } else if (permissions.userAccess === VUES.ADVISOR) {
            permissions.hide_fees = relevantGroup?.settings?.hide_fees_for_advisors;
        }
        permissions.advisor_logo_thumb = relevantGroup?.settings?.logo_thumb_url || null;
        permissions.advisor_logo_in_toolbar = relevantGroup?.settings?.logo_in_toolbar || false;
    }

    // save permissions to singleton
    thisUserPermissions = permissions;
    return permissions;
};
