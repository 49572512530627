import React, { useEffect, useState } from 'react';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';

interface ReSLAButtonProps {
    investmentId?: string;
    userId?: string;
    loading: (arg1: any, arg2: string) => Promise<void>;
    loaded: (arg: string) => Promise<void>;
    setAlert: (alert: any, type?: string | undefined) => Promise<void>;
    text?: string;
    doneText?: string;
}

const ReSLAButton: React.FC<ReSLAButtonProps> = (props) => {
    const [reSLAed, setReSLAed] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const idStr = JSON.stringify({ investmentId: props.investmentId, userId: props.userId });

    // turn off re-SLAing when the investment or user ID changes
    useEffect(() => {
        setReSLAed(false);
    }, [idStr]);

    const reSLA = async () => {
        if (reSLAed) {
            setReSLAed(false);
            return;
        }

        if (!confirm) {
            setConfirm(true);
            setTimeout(() => setConfirm(false), 3000);
            return;
        }
        setConfirm(false);
        if (props.investmentId || props.userId) {
            await props.loading(null, 'reSLA-button');
            let run_response;
            try {
                run_response = await api2.client.AuditResultApi.runSlaIssues({
                    RunSlaIssuesRequest: {
                        user_id: props.userId,
                        investment_id: props.investmentId,
                    },
                });
            } catch (error) {
                console.log(error);
                setDismissableAlert(props.setAlert, 'Error running SLA issues', true);
            }
            await props.loaded('reSLA-button');
            setDismissableAlert(props.setAlert, run_response?.data?.message ?? 'SLA issues run successfully');
            setReSLAed(true);
        } else {
            console.log('no investment or user ID');
        }
    };

    const text = props.text ?? 'Re-SLA';
    const doneText = props.doneText ?? 'Re-SLA complete!';

    return (
        <span
            id="reSLAButton"
            className="a pdfIgnore"
            onClick={reSLA}
            style={{
                fontSize: '16px',
            }}
        >
            {confirm ? 'Confirm?' : reSLAed ? doneText : text}
        </span>
    );
};

export default ReSLAButton;
