// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EM_DASH } from '../../constants/constantStrings';
import '../../styles/performanceOverview.css';
import '../../styles/widget.css';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { calcAmountInvested } from '../../utilities/calculate/financeCalculations';
import formatCurrency from '../../utilities/format/formatCurrency';

// const isMobile = window.innerWidth <= 768;

interface PortfolioOverviewProps extends RouteComponentProps {
    investments: any[];
    account?: any;
    relevantUser?: any;
}

const PortfolioOverview: React.FC<PortfolioOverviewProps> = (props) => {
    const sum = useCallback((arr: any[], func: (x: any) => number) => arr.reduce((acc, x) => acc + func(x), 0), []);

    const getData = useCallback(() => {
        const p = props;
        if (!p.investments || !p.investments.length) return [];
        const committed = sum(p.investments, (i) => i.performance?.itd?.committed ?? 0);
        const invested = calcAmountInvested(p.investments);
        const distributions = sum(p.investments, (i) => i.performance?.itd?.distributions ?? 0);
        const curValue = sum(p.investments, (i) => i.valuation ?? 0);
        const fees = sum(p.investments, (i) => (i.performance?.itd?.fees ? i.performance?.itd?.fees : 0));
        return [
            {
                label: 'Total Commitments',
                value: () => formatCurrency(committed),
            },
            {
                label: 'Amount Invested',
                value: () => {
                    return formatCurrency(invested);
                },
            },
            {
                label: '% Called',
                value: () => {
                    const percentCalled = (invested / committed) * 100;
                    return isFinite(percentCalled) ? `${percentCalled.toFixed(2)}%` : EM_DASH;
                },
            },
            {
                label: 'Total Distributions',
                value: () => {
                    return formatCurrency(distributions);
                },
            },
            {
                label: 'Current Portfolio Value',
                value: () => {
                    return formatCurrency(curValue);
                },
            },
            {
                label: 'Total Portfolio Value',
                value: () => {
                    return formatCurrency(distributions + curValue);
                },
            },
            {
                label: 'Annualized Return',
                value: () => {
                    return `${((p.account?.performance?.irr_by_account ?? p.relevantUser?.performance?.irr_by_user ?? 0) * 100).toFixed(2)}%`;
                },
            },
            ...(!UserPermissions().hide_fees
                ? [
                      {
                          label: 'Fees / Total Amount Invested',
                          value: () => {
                              return invested ? `${((fees / invested) * 100).toFixed(2)}%` : EM_DASH;
                          },
                      },
                  ]
                : []),
        ];
    }, [props, sum]);

    useEffect(() => {
        // Any side effects or data fetching can be done here
    }, [props]);

    const data = getData();

    return (
        <div className="widget PortfolioOverview_component" style={{ position: 'relative', height: '100%' }}>
            <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Portfolio Overview</h1>

            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                    {data.map((d, dIdx) => {
                        return (
                            <tr key={`tr_${dIdx}`} style={{ position: 'relative', height: '40px', fontSize: '18px' }}>
                                <td style={{ width: '70%', fontWeight: 'bold' }}>{d.label}</td>
                                <td style={{ width: '30%' }}>{d.value()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default withRouter(PortfolioOverview);
