import React, { useEffect, useState } from 'react';
import renderInputError, { ErrorRecord } from '../renderInputError';
import api2 from '../../api2';
import { KeyValuePairList, Entry as KVListEntry } from '../DrawerComponents/KeyValuePairList';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import '../../styles/addEditUnrealizedValueDrawer.css';
import { VUES } from '../../constants/constantStrings';
import { openBulkUploaderManager } from '../Uploaders/BulkUploader/BulkUploaderManager/BulkUploaderManager';
import { BulkUploadType } from '../Uploaders/BulkUploader/types/bulkUploadTypes';

interface ValuationEntry extends Entry {
    _id: string;
    date: string;
    amount: number;
    investment_id: string;
}

interface PaginateParams {
    page?: number;
    limit?: number;
    sort?: string;
    order?: 'asc' | 'desc';
}

interface Investment {
    _id: string;
    currency?: string;
}

interface Props {
    investment: Investment;
    closeDrawer: () => void;
    loading: (time?: number, key?: string) => void;
    loaded: (key: string) => void;
    setAlert: (message: string | React.ReactNode, action?: (() => void) | null, error?: boolean) => void;
    reloadData: () => Promise<void>;
    allUserInvestments?: Array<{ user: { _id: string } | string }>;
    modal?: any;
    vue?: string;
    advisorVue?: boolean;
}

interface Entry {
    [key: string]: any;
    _id: string;
}

interface ValuationApiResponse {
    data: {
        valuation: ValuationEntry;
        valuation_id?: string;
        valuations?: ValuationEntry[];
    };
}

interface ApiError {
    response?: {
        status: number;
        data: {
            message: string;
        };
    };
    message: string;
}

const sanitizeNumberStr = (str: string): string => {
    return str.replace(/[^0-9.]/g, '');
};

const logError = (message: string, error: unknown) => {
    const err = error as ApiError;
    console.error(message, err.response?.data?.message || err.message);
};

const AddEditUnrealizedValueDrawer: React.FC<Props> = ({ investment, closeDrawer, loading, loaded, setAlert, reloadData, allUserInvestments, modal, vue, advisorVue }) => {
    const [data, setData] = useState<ValuationEntry[]>([]);
    const [entries, setEntries] = useState<ValuationEntry[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const loadData = async (investment: Investment) => {
        if (!investment?._id) return;

        loading(320, 'addEditUnrealizedValueDrawer');
        try {
            const response = await api2.paginateApiRoute(async (paginate_params: PaginateParams) => {
                const result = await api2.client.ValuationApi.listValuations({
                    investments: [investment._id],
                    ...paginate_params,
                });

                return (result.data.valuations || []) as ValuationEntry[];
            });

            if (Array.isArray(response)) {
                setData(response);
                setEntries(response);
            }
        } catch (err) {
            logError('Error getting valuations', err);
            setDismissableAlert(setAlert, 'Error loading valuations', true);
        } finally {
            loaded('addEditUnrealizedValueDrawer');
        }
    };

    useEffect(() => {
        if (investment?._id) {
            loadData(investment);
        }
    }, [investment?._id]);

    const isSafeToSave = (setErrorsFlag = true): boolean => {
        const newErrors: { [key: string]: string[] } = {};

        for (const element of entries) {
            const amount = typeof element.amount === 'string' ? Number(sanitizeNumberStr(element.amount)) : element.amount;

            if (!element.date) {
                newErrors.date = [...(newErrors.date || []), 'Date is required'];
            }

            if (!Number.isFinite(amount)) {
                newErrors.amount = [...(newErrors.amount || []), 'Amount must be a valid number'];
            }

            const today = new Date().toISOString().split('T')[0];
            if (element.date?.split('T')?.[0] > today) {
                newErrors.date = [...(newErrors.date || []), 'Date cannot be in the future'];
            }
        }

        const isSafe = Object.keys(newErrors).length === 0;

        if (setErrorsFlag) {
            if (!isSafe && !newErrors.submit) {
                newErrors.submit = ['Please correct the errors above'];
            }
            setErrors(newErrors);
        }

        return isSafe;
    };

    const makeDataCorrections = (entries: ValuationEntry[]): ValuationEntry[] => {
        return entries.map((entry) => {
            const correctedDate = dateToUTCMidnight(entry.date).toISOString().split('T')[0];
            const correctedAmount = Number(typeof entry.amount === 'string' ? sanitizeNumberStr(entry.amount) : entry.amount);

            return {
                ...entry,
                date: correctedDate,
                amount: correctedAmount,
            };
        });
    };

    const removeEntry = async (id: string) => {
        try {
            await api2.client.ValuationApi.deleteValuation({
                valuation_id: id,
            });
            setData(data.filter((d) => d._id !== id));
        } catch (err) {
            logError('Error deleting valuation', err);
        }
    };

    const getUserId = () => (typeof allUserInvestments?.[0]?.user === 'string' ? allUserInvestments[0].user : allUserInvestments?.[0]?.user?._id);

    const addOrUpdateValuations = async () => {
        if (!isSafeToSave() || isSubmitting) return;

        setIsSubmitting(true);
        loading(0, 'addEditUnrealizedValueDrawer');

        try {
            const correctedEntries = makeDataCorrections(entries);
            const reqBody = advisorVue && getUserId() ? { user: getUserId() } : {};

            const toCreate = correctedEntries.filter((entry) => !entry._id);
            const toUpdate = correctedEntries.filter((entry) => entry._id);

            const apiCalls = [
                ...toUpdate.map(async (update_val) => {
                    try {
                        await api2.client.ValuationApi.updateValuation({
                            valuation_id: update_val._id,
                            UpdateValuationRequest: {
                                amount: update_val.amount,
                                date: update_val.date,
                            },
                        });

                        const response = await api2.client.ValuationApi.getValuation({
                            valuation_id: update_val._id,
                        });

                        if (!response.data.valuation) {
                            throw new Error('No valuation data returned');
                        }

                        return response.data.valuation;
                    } catch (err) {
                        logError('Error updating valuation', err);
                        return null;
                    }
                }),
                ...toCreate.map(async (create_val) => {
                    try {
                        const createRes = (await api2.client.ValuationApi.createValuation({
                            CreateValuationRequest: {
                                ...reqBody,
                                investment: investment._id,
                                date: create_val.date,
                                amount: create_val.amount,
                            },
                        })) as unknown as ValuationApiResponse;

                        if (!createRes.data.valuation_id) {
                            throw new Error('No valuation ID returned');
                        }

                        const response = (await api2.client.ValuationApi.getValuation({
                            valuation_id: createRes.data.valuation_id,
                        })) as unknown as ValuationApiResponse;

                        return response.data.valuation;
                    } catch (err) {
                        logError('Error creating valuation', err);
                        return null;
                    }
                }),
            ];

            const values = await Promise.all(apiCalls);
            const failures = values.filter((v) => !v);

            if (failures.length > 0) {
                setDismissableAlert(setAlert, 'There was an error saving some valuations.', true);
            } else {
                setDismissableAlert(setAlert, 'Valuations have been updated successfully.');
                await reloadData();
                setData(values.filter((v): v is ValuationEntry => v !== null));
                closeDrawer();
            }
        } catch (err) {
            logError('Error processing valuations', err);
            setDismissableAlert(setAlert, 'There was an error processing the valuations.', true);
        } finally {
            setIsSubmitting(false);
            loaded('addEditUnrealizedValueDrawer');
        }
    };

    const renderBulkUploadBtn = () => {
        const allowBulkUpload = vue !== VUES.INVESTOR;
        if (!allowBulkUpload) return null;

        return (
            <div className="ddt-tabElem ddt-addBtn" onClick={() => openBulkUploaderManager(modal, BulkUploadType.valuation, { investment: investment?._id }, true)}>
                <div className="plus-icon" />
                Bulk Upload
            </div>
        );
    };

    const sortedData = [...data].sort((a, b) => a.date.localeCompare(b.date));

    return (
        <>
            <div className="addEditCommitted-drawer">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative',
                        marginBottom: '20px',
                    }}
                >
                    <h1 style={{ marginBottom: '0px' }}>Edit Unrealized Value</h1>
                    {renderBulkUploadBtn()}
                </div>
                <div className="addEditCommitted-drawer-keyValueList">
                    <KeyValuePairList
                        errors={errors}
                        keyList={[
                            {
                                title: 'Transaction Date',
                                placeholder: 'Date',
                                field: 'date',
                                type: 'date',
                            },
                            {
                                title: 'Unrealized Value',
                                placeholder: 'Value',
                                field: 'amount',
                                type: 'text',
                            },
                            {
                                title: '',
                                placeholder: '',
                                field: '',
                                type: 'delete',
                            },
                        ]}
                        data={sortedData as Entry[]}
                        removeEntry={removeEntry}
                        handleChange={(data: KVListEntry[]) => {
                            const updatedEntries = data.map((entry) => ({
                                ...entry,
                                _id: (entry as ValuationEntry)._id || '',
                                date: (entry as ValuationEntry).date || '',
                                amount: entry.amount || 0,
                                investment_id: entry.investment_id || '',
                            }));
                            setEntries(updatedEntries as ValuationEntry[]);
                            isSafeToSave(true);
                        }}
                    />
                </div>
                <div>
                    <button className="drawer-submit-button" onClick={addOrUpdateValuations} disabled={!isSafeToSave(false)}>
                        Save
                    </button>
                    <div className="addEditCommitted-cancel">
                        <span className="a" onClick={closeDrawer}>
                            Cancel
                        </span>
                    </div>
                    {renderInputError(errors as unknown as ErrorRecord, 'submit')}
                    <div style={{ clear: 'both', height: '20px' }} />
                </div>
            </div>
            <div style={{ clear: 'both', height: '300px' }} />
        </>
    );
};

export default AddEditUnrealizedValueDrawer;
