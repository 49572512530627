import { AuditLog } from './AuditLog';
import { Currency } from './Currency';

export enum InvestmentStatuses {
    fundraising = 'fundraising',
    investing = 'investing',
    harvesting = 'harvesting',
    liquidated = 'liquidated',
}

export interface InvestmentUser {
    _id: string;
    name: string;
    email: string;
}

export interface InvestmentAccount {
    _id: string;
    name: string;
}

export interface InvestmentAssetManager {
    _id: string;
    name: string;
}

export interface InvestmentInvestmentMaster {
    _id: string;
    name: string;
    ticker: string;
    asset_manager: InvestmentAssetManager | null;
}

export interface Investment {
    _id: string;
    type: string;
    name: string;
    user: InvestmentUser | string;
    account: InvestmentAccount | string;
    connection?: string;
    last_recalculate_time?: string;
    hidden: boolean;
    incomplete: boolean;
    inactive: boolean;
    inactive_date?: string;
    initial_contribution?: number;
    investment_data?: Record<string, any>;
    investment_master?: InvestmentInvestmentMaster | null;
    currency: Currency;
    performance?: Record<string, any>;
    valuation?: number;
    beginning?: number;
    tax_document_requested?: string;
    expected_tax_documents: number;
    started_at?: string;
    first_cashflow_date?: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    audit_log?: AuditLog[];
    exclude_from_billing: boolean;
    amount: number;
    color: string;
    most_recent_valuation?: {
        amount: number;
        date: string;
    };
    most_recent_transaction?: {
        amount: number;
        date: string;
    };
}
export interface InvestmentType {
    // Add InvestmentType here
    name: string;
    amount: number;
    investments?: number[]; // Additional field for investments breakdown
}
