import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import validator from 'validator';
import GenericDrawer from './GenericDrawer';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { STATES } from '../../constants/constantStrings';
import { isEIN, isSSN, isTIN } from '../../utilities/validation/validators';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import AuditLogDisplay from '../AuditLogDisplay';
import { CreateAccountRequest } from '../../openApiClient';
import '../../styles/addConnectionDrawer.css';

export const ID_TYPES = [
    { name: 'Employer Identification Number (EIN)', value: 'EIN' },
    { name: 'Social Security Number (SSN)', value: 'SSN' },
    { name: 'Tax Identification Number (TIN)', value: 'TIN' },
];

export const ACCOUNT_TYPES = [
    { name: 'Individual', value: 'individual' },
    { name: 'Joint', value: 'joint' },
    { name: 'Trust', value: 'trust' },
    { name: 'Individual Retirement Account (IRA)', value: 'ira' },
    { name: 'Limited Liability Company (LLC)', value: 'llc' },
    { name: 'Partnership', value: 'partnership' },
    { name: 'Corporation', value: 'corporation' },
    { name: 'Other', value: 'other' },
];

interface ApiAccount {
    _id: string;
    name: string;
    account_type?: string;
    id_type?: string;
    id_number?: string;
    address?: {
        streetLine1?: string;
        streetLine2?: string;
        city?: string;
        state?: string;
        zipCode?: string;
        country?: string;
    };
    audit_log?: any[];
}

interface Account extends Required<Pick<ApiAccount, '_id' | 'name'>> {
    account_type?: string;
    id_type?: string;
    id_number?: string;
    address?: ApiAccount['address'];
    audit_log?: ApiAccount['audit_log'];
}

interface Props extends RouteComponentProps {
    accountId?: string;
    userId?: string;
    account?: ApiAccount;
    close: () => void;
    loading: (duration?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: string | JSX.Element, isError?: boolean, duration?: number) => void;
    reloadData: () => Promise<void>;
    vue?: string;
}

const AddEditAccountDrawer: React.FC<Props> = ({ accountId, userId, account: propAccount, close, loading, loaded, setAlert, reloadData, vue }) => {
    const [account, setAccount] = useState<Account | null>(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (propAccount) {
            setAccount(propAccount as Account);
        } else {
            loadData();
        }
    }, [accountId, propAccount]);

    const loadData = async () => {
        if (!accountId) return null;

        try {
            const response = await api2.client.AccountApi.getAccount({
                account_id: accountId,
            });

            const loadedAccount = response.data.account;
            if (!loadedAccount?._id || !loadedAccount?.name) return null;

            const typedAccount: Account = {
                _id: loadedAccount._id,
                name: loadedAccount.name,
                account_type: loadedAccount.account_type,
                id_type: loadedAccount.id_type,
                id_number: loadedAccount.id_number,
                address: loadedAccount.address,
                audit_log: loadedAccount.audit_log,
            };

            setAccount(typedAccount);

            return {
                name: typedAccount.name,
                account_type: ACCOUNT_TYPES.find((a) => a.value === typedAccount.account_type),
                id_type: ID_TYPES.find((a) => a.value === typedAccount.id_type),
                id_number: typedAccount.id_number,
                streetLine1: typedAccount.address?.streetLine1,
                streetLine2: typedAccount.address?.streetLine2,
                city: typedAccount.address?.city,
                state: typedAccount.address?.state,
                zipCode: typedAccount.address?.zipCode,
                country: typedAccount.address?.country,
            };
        } catch (error) {
            console.error('Error loading account:', error);
            return null;
        }
    };

    const validateForm = (data: any) => {
        const errors: Record<string, string> = {};

        if (!data.name) {
            errors.name = 'Name is required';
        }

        if (data.zipCode && !validator.isPostalCode(String(data.zipCode), 'US') && !validator.isPostalCode(String(data.zipCode), 'CA')) {
            errors.zipCode = 'Invalid Zip Code';
        }

        if (data.id_type?.value) {
            if (!data.id_number) {
                errors.id_number = 'ID Number is required when ID Type is set';
            } else {
                const validators = {
                    SSN: isSSN,
                    EIN: isEIN,
                    TIN: isTIN,
                };
                const validatorFn = validators[data.id_type.value as keyof typeof validators];
                if (validatorFn && !validatorFn(data.id_number)) {
                    errors.id_number = `Invalid ${data.id_type.value}`;
                }
            }
        }

        return Object.keys(errors).length ? errors : null;
    };

    const getFields = (state: any) => ({
        Name: {
            placeholder: 'Enter Name',
            label: 'Name',
            fieldName: 'name',
            type: 'text',
            required: true,
            className: 'addNewAdvisorToFirm-input',
        },
        'ID Type': {
            placeholder: 'Select ID Type',
            label: 'ID Type',
            fieldName: 'id_type',
            type: 'option',
            optionField: 'name',
            options: ID_TYPES,
            optionClearable: true,
            className: 'addNewAdvisorToFirm-select',
        },
        'ID Number': {
            placeholder: 'Enter ID Number',
            label: 'ID Number',
            fieldName: 'id_number',
            type: account ? 'password' : 'text',
            allowShow: true,
            className: 'addNewAdvisorToFirm-input',
        },
        'Account Type': {
            placeholder: 'Select Account Type',
            label: 'Account Type',
            fieldName: 'account_type',
            type: 'option',
            optionField: 'name',
            options: ACCOUNT_TYPES,
            optionClearable: true,
            className: 'addNewAdvisorToFirm-select',
        },
        'Street Line 1': {
            placeholder: 'Enter Street Line 1',
            label: 'Street Line 1',
            fieldName: 'streetLine1',
            type: 'text',
            className: 'addNewAdvisorToFirm-input',
        },
        'Street Line 2': {
            placeholder: 'Enter Street Line 2',
            label: 'Street Line 2',
            fieldName: 'streetLine2',
            type: 'text',
            className: 'addNewAdvisorToFirm-input',
        },
        City: {
            placeholder: 'Enter City',
            label: 'City',
            fieldName: 'city',
            type: 'text',
            className: 'addNewAdvisorToFirm-input',
        },
        State: {
            placeholder: 'Enter State eg. CA, NY, etc.',
            label: 'State',
            fieldName: 'state',
            type: 'select',
            options: STATES,
            optionClearable: true,
            className: 'addNewAdvisorToFirm-select',
        },
        'Zip Code': {
            placeholder: 'Enter Zip Code',
            label: 'Zip Code',
            fieldName: 'zipCode',
            type: 'text',
            className: 'addNewAdvisorToFirm-input',
        },
        Country: {
            placeholder: 'Enter Country',
            label: 'Country',
            fieldName: 'country',
            type: 'select',
            options: ['US', 'CA'],
            optionClearable: true,
            className: 'addNewAdvisorToFirm-select',
        },
    });

    const handleSubmit = async (data: any): Promise<void> => {
        const errors = validateForm(data);
        if (errors) {
            throw errors;
        }

        const reqBody = {
            name: data.name,
            id_type: data.id_type?.value ?? '',
            id_number: data.id_number ?? '',
            address: {
                streetLine1: data.streetLine1 ?? '',
                streetLine2: data.streetLine2 ?? '',
                city: data.city ?? '',
                state: data.state ?? '',
                zipCode: data.zipCode ?? '',
                country: data.country ?? '',
            },
            account_type: data.account_type?.value ?? '',
            user: userId ?? '',
        };

        loading(320, 'postPatchAccount');

        try {
            if (!account) {
                const response = await api2.client.AccountApi.createAccount({
                    CreateAccountRequest: reqBody as CreateAccountRequest,
                });
                const newAccount = await api2.client.AccountApi.getAccount({
                    account_id: response.data.account_id as string,
                });
                setAccount(newAccount.data.account as Account);
            } else {
                await api2.client.AccountApi.updateAccount({
                    account_id: account._id,
                    UpdateAccountRequest: reqBody,
                });
                const updatedAccount = await api2.client.AccountApi.getAccount({
                    account_id: account._id,
                });
                setAccount(updatedAccount.data.account as Account);
            }

            setDismissableAlert(setAlert, `The account has been ${account ? 'updated' : 'created'}`);
            await reloadData();
            close();
        } catch (error) {
            console.error('Error saving account:', error);
            setDismissableAlert(setAlert, `Error ${account ? 'updating' : 'creating'} account. Ensure field validity`, true);
            throw error;
        } finally {
            loaded('postPatchAccount');
        }
    };

    const handleDelete = async () => {
        if (!account) return;

        if (!confirmDelete) {
            setConfirmDelete(true);
            setTimeout(() => {
                setConfirmDelete(false);
            }, 3000);
            return;
        }

        loading(0, 'accountDrawer_deactivateAccount');
        try {
            await api2.client.AccountApi.deleteAccount({
                account_id: account._id,
            });
            await reloadData();
            setDismissableAlert(setAlert, 'Account deactivated.');
            close();
        } catch (error) {
            console.error('Error deactivating account:', error);
            setDismissableAlert(setAlert, 'Error deactivating account.', true);
        } finally {
            loaded('accountDrawer_deactivateAccount');
        }
    };

    return (
        <GenericDrawer
            title={account ? `Edit Entity ${account.name}` : 'Create New Entity'}
            actionStr={account ? 'Save' : 'Create'}
            loadingKey="accountDrawer"
            getFields={getFields}
            onSubmit={handleSubmit}
            close={close}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            className="addNewAdvisorToFirm-drawer"
            extraContent={() => {
                return (
                    <>
                        {account && (
                            <div
                                data-testid="delete-button"
                                onClick={handleDelete}
                                className="addNewAdvisorToFirm-deactivate"
                                style={{
                                    color: 'var(--color-dark-red)',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                {confirmDelete ? 'Confirm Deactivate Account' : 'Deactivate Account'}
                            </div>
                        )}
                        <br></br>
                        {account && UserPermissions().access.admin && (
                            <div data-testid="audit-log-section" className="addNewAdvisorToFirm-audit">
                                {/* <h3>Audit Log</h3> */}
                                <AuditLogDisplay
                                    fetchLogs={async () => {
                                        try {
                                            const response = await api2.client.AccountApi.getAccount({
                                                account_id: account._id,
                                                populate_audit_log: true,
                                            });
                                            return (response.data.account?.audit_log ?? []).map((log) => ({
                                                id: String(Date.now()),
                                                timestamp: log.timestamp || new Date().toISOString(),
                                                action: log.action || '',
                                                initiator_type: log.initiator_type || '',
                                                initiator: log.initiator || {},
                                                action_details: log.action_details || {},
                                            }));
                                        } catch (error) {
                                            console.error('Error loading audit logs:', error);
                                            return [];
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </>
                );
            }}
        />
    );
};

export default withRouter(AddEditAccountDrawer);
